import React from 'react'
import { useIntl } from 'react-intl'
import styles from './style.module.scss'

interface StepTitleProps {
  index: number,
  title: string
}

const StepTitle = (
  props: StepTitleProps,
) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles['title-bar']}>
      <div className={styles['title-index']}>
        <div>{ formatMessage({ id: 'form.title.index' }) }</div>
        <div>{props.index}</div>
      </div>
      <div className={styles.title}>
        {props.title}
      </div>
    </div>
  )
}

export default StepTitle
