import { get } from 'lodash'
import { axios } from 'qurtty-js-sdk'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { PayPalButton } from 'react-paypal-button-v2'
import DonationHookContext from '../../contexts/DonationHookContext'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'
import useDonationFormFields from '../../hooks/useDonationFormFields'
import usePaypalBillingPlanDatum from '../../hooks/usePaypalBillingPlanDatum'
import usePaypalGatewayConfig from '../../hooks/usePaypalGatewayConfig'
import PaymentLoading from '../DonateForm/components/PaymentLoading'
import PaidSuccessful from '../PaidSuccessful'

const SubscriptionDonationPayPal = () => {
  const { formatMessage } = useIntl()
  const paypalGatewayConfigHook = usePaypalGatewayConfig()
  const requestFormHook = useContext(RequestFormHookContext)
  const donationFormFieldsHook = useDonationFormFields()
  const donationFormData = donationFormFieldsHook.getFormData()
  const donationHook = useContext(DonationHookContext)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const subscriptionIntentId: any = requestFormHook.currentRequest?.subscription_intents?.[0]?.id

  const selectedDonationAmount = donationHook.monthlyDonationAmountProducts.find((amount) => amount.name_en === donationFormData.donationAmount)
  const billingPlan = selectedDonationAmount.getBillingPlans()[0]
  const paypalBillingPlanDatumHook = usePaypalBillingPlanDatum(get(billingPlan, 'paypal_billing_plan_datum'))

  const isMonthlyPayment = donationHook.isMonthlyDonation
  const isPaypal = donationFormData.paymentMethod === formatMessage({ id: 'payment.method.paypal' })

  const onApprove = async (data, actions) => {
    setLoading(true)
    await axios.post(`/subscription_intents/${subscriptionIntentId}/paypal_gateway/subscription_callback`, {
      order_ref_id: get(data, 'orderID'),
      subscription_ref_id: get(data, 'subscriptionID'),
    })

    setDone(true)
    setLoading(false)
  }

  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: paypalBillingPlanDatumHook.paypalPlanId,
      // plan_id: 'P-9V0280538U2711709MCFXNBI'
    })
  }

  if (!isMonthlyPayment || !isPaypal) {
    return <div />
  }

  if (done) {
    return (
      <PaidSuccessful />
    )
  }

  if (loading) {
    return (
      <PaymentLoading />
    )
  }

  return (
    <PayPalButton
      style={{ shape: 'rect', color: 'gold', layout: 'vertical', label: 'pay' }}
      options={{ clientId: paypalGatewayConfigHook.clientId, vault: true }}
      createSubscription={createSubscription}
      onApprove={onApprove}
    />
  )
}

export default SubscriptionDonationPayPal
