import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'
import useDonationFormFields from '../../hooks/useDonationFormFields'
import FpsPaymentCard from '../DonateForm/components/FpsPaymentCard'
import PaidSuccessful from '../PaidSuccessful'

const OneTimeDonationFps = () => {
  const { formatMessage } = useIntl()
  const donationFormFieldsHook = useDonationFormFields()
  const requestFormHook = useContext(RequestFormHookContext)
  const donationFormData = donationFormFieldsHook.getFormData()
  const isFps = donationFormData.paymentMethod === formatMessage({ id: 'payment.method.fps' })

  if (!isFps) {
    return <div />
  }

  return (
    <>
      <PaidSuccessful />

      <FpsPaymentCard fpsBeneficiaryConfigs={[requestFormHook.currentRequest.getOrder().fps_payment_datum.fps_beneficiary_config]} />
    </>
  )
}

export default OneTimeDonationFps
