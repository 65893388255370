import IntlMessageFormat from 'intl-messageformat'
import lang from '../constants/lang'

const AGES_TYPES = (locale) => {
  return [
    {
      value: 0,
      label: '18',
    },
    {
      value: 1,
      label: '19 ~ 26',
    },
    {
      value: 2,
      label: '27 ~ 36',
    },
    {
      value: 3,
      label: '37 ~ 46',
    },
    {
      value: 4,
      label: new IntlMessageFormat(lang[locale]['age.range.above']).format({ age: 47 }),
    },
  ]
}

export default AGES_TYPES
