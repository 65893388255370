import { session } from 'qurtty-js-sdk'
import { useState } from 'react'
import useFetchingState from './common/useFetchingState'

const useRequestForm = ({ requestGroup }) => {
  const createRequestState = useFetchingState()
  const [currentRequest, setCurrentRequest] = useState(null)
  const [formValues, setFormValues] = useState(null)

  const createRequest = async (params: any = {}) => {
    try {
      createRequestState.markLoading()

      const request = await requestGroup.$createAssociation('requests', { includes: JSON.stringify({ order: session.V3_Order.INCLUDES_PARAMS, subscription_intents: {} }), ...params })

      setCurrentRequest(request)

      createRequestState.markSuccess()
    } catch (e) {
      console.error(e)
      createRequestState.markError(e)
    }
  }

  return {
    createRequestState,
    requestGroup,
    currentRequest,
    createRequest,
    formValues,
    setFormValues,
  }
}

export default useRequestForm
