import { Button, LinearProgress, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import DonationConfirmList from '../../components/DonationConfirmList'
import OneTimeDonationBankTransfer from '../../components/OneTimeDonationBankTransfer'
import OneTimeDonationFps from '../../components/OneTimeDonationFps'
import OneTimeDonationMpgs from '../../components/OneTimeDonationMpgs'
import OneTimeDonationPayPal from '../../components/OneTimeDonationPayPal'
import SubscriptionDonationPayPal from '../../components/SubscriptionDonationPayPal'
import DonationHookContext from '../../contexts/DonationHookContext'
import ProjectHookContext from '../../contexts/ProjectHookContext'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'
import useDonation from '../../hooks/useDonation'
import useDonationFormFields from '../../hooks/useDonationFormFields'
import useFormRequestConverter from '../../hooks/useFormRequestConverter'

const ConfirmPage = () => {
  const projectHook = useContext(ProjectHookContext)
  const donationFormFieldsHook = useDonationFormFields()
  const donationFormData: any = donationFormFieldsHook.getFormData()
  const { lo } = useParams<any>()
  const classes = useStyles()
  const requestFormHook = useContext(RequestFormHookContext)
  const formRequestConverterHook = useFormRequestConverter(requestFormHook.formValues)
  const history = useHistory()

  const donationHook = useDonation(requestFormHook.formValues)

  useEffect(() => {
    if (isEmpty(requestFormHook.formValues)) {
      history.push(`/${lo}/form`)
    }
  }, [JSON.stringify(requestFormHook.formValues)])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (requestFormHook.createRequestState.isError) {
      alert(requestFormHook.createRequestState.errorMsg)
      return
    }
    if (requestFormHook.createRequestState.isSuccess) {
      const orderUpdate = async () => {
        const formData = new FormData()
        const paymentGatewayConfig = projectHook.project.website_control.payment_gateway_config
        const order = requestFormHook.currentRequest.getOrder()
        formData.append('gateway_provider', donationFormData.paymentMethod.includes('FPS') ? 'fps' : 'bank_transfer')
        formData.append('ref_id', '')
        donationFormData.receiptFile && formData.append('receipt', donationFormData.receiptFile)
        const fpsOrBankConfigId = donationFormData.paymentMethod.includes('FPS') ? paymentGatewayConfig.fps_beneficiary_configs.toModelArray()[0].id : paymentGatewayConfig.bank_transfer_beneficiary_configs.toModelArray()[0].id
        const paymentDatumAttributes = !donationFormData.paymentMethod.includes('FPS') ? { bank_transfer_beneficiary_config_id: fpsOrBankConfigId, payment_date: null, channel: 'e_banking' } : { fps_beneficiary_config_id: fpsOrBankConfigId, payment_date: null }
        Object.keys(paymentDatumAttributes).forEach((key) => formData.append(!donationFormData.paymentMethod.includes('FPS') ? `bank_transfer_payment_datum_attributes[${key}]` : `fps_payment_datum_attributes[${key}]`, paymentDatumAttributes[key]))
        await order.$save(formData)
        await order.$refresh()
      }
      if (donationFormData.paymentMethod !== 'PayPal') {
        orderUpdate()
      }
    }
  }, [requestFormHook.createRequestState.isDone])

  const onSubmit = async () => {
    await requestFormHook.createRequest(formRequestConverterHook.convertToRequestAttributes(requestFormHook.formValues))
  }

  if (isEmpty(requestFormHook.formValues)) {
    return <LinearProgress />
  }

  const renderComfirmContent = () => (
    <div className={classes.root}>
      <DonationConfirmList />

      <div className={classes.actionContainer}>
        {!requestFormHook.createRequestState.isSuccess && <Button variant='outlined' onClick={onSubmit}>Submit</Button>}
        {
          requestFormHook.createRequestState.isSuccess && (
            <div>
              <OneTimeDonationPayPal />
              <OneTimeDonationFps />
              <OneTimeDonationMpgs />
              <OneTimeDonationBankTransfer />
              <SubscriptionDonationPayPal />
            </div>
          )
        }
      </div>
    </div>
  )

  return (
    <DonationHookContext.Provider value={donationHook}>
      { renderComfirmContent() }
    </DonationHookContext.Provider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 750,
    margin: '0 auto',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
    },
  },
  actionContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
  },
}))

export default ConfirmPage
