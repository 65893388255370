import { Button } from '@material-ui/core'
import { get } from 'lodash'
import { axios } from 'qurtty-js-sdk'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ProjectHookContext from '../../contexts/ProjectHookContext'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'
import useDonationFormFields from '../../hooks/useDonationFormFields'
import useMpgsGatewayConfig from '../../hooks/useMpgsGatewayConfig'
import PaymentLoading from '../DonateForm/components/PaymentLoading'
import PaidSuccessful from '../PaidSuccessful'

const OneTimeDonationMpgs = () => {
  const { formatMessage } = useIntl()
  const requestFormHook = useContext(RequestFormHookContext)
  const projectHook = useContext(ProjectHookContext)
  const mpgsGatewayConfigHook = useMpgsGatewayConfig()
  const order = requestFormHook.currentRequest.getOrder()
  const orderId = get(order, 'id')
  const currency = get(order, 'currency')
  const projectName = get(projectHook, 'project.name')

  const donationFormFieldsHook = useDonationFormFields()
  const donationFormData = donationFormFieldsHook.getFormData()
  const isOneTimePayment = donationFormData.donationType === 'One-Time Donation'

  const isMpgs = donationFormData.paymentMethod === formatMessage({ id: 'payment.method.mpgs' })

  const shouldLoad = order && currency && isOneTimePayment && isMpgs

  const [sessionId, setSessionId] = useState('')

  const [loading, setLoading] = useState(true)
  const [done, setDone] = useState(false)

  const injectMpgsScript = (sessionId) => {
    setLoading(true);

    (window as any).mpgsCompleteCallback = async (resultIndicator, sessionVersion) => {
      setLoading(true)
      await axios.post(`/orders/${orderId}/mpgs_gateway/purchase_callback`, { session_id: sessionId })
      setLoading(false)
      setDone(true)
    };

    (window as any).mpgsErrorCallback = (error) => {
      console.log(JSON.stringify(error))
      console.log('Payment error')
    };

    (window as any).mpgsCancelCallback = () => {
      console.log('Payment cancelled')
    }

    const mpgsScriptTag = document.createElement('script')
    mpgsScriptTag.setAttribute('src', process.env.REACT_APP_MPGS_CHECKOUT_JS_SRC || '')
    mpgsScriptTag.setAttribute('data-error', 'mpgsErrorCallback')
    mpgsScriptTag.setAttribute('data-cancel', 'mpgsCancelCallback')
    mpgsScriptTag.setAttribute('data-complete', 'mpgsCompleteCallback')
    document.body.appendChild(mpgsScriptTag)

    console.log(
      {
        merchant: mpgsGatewayConfigHook.merchantId,
        order: {
          description: formatMessage({ id: 'online.donation' }),
        },
        interaction: {
          merchant: {
            name: String(projectName || '').substr(0, 40),
          },
        },
        session: {
          id: sessionId,
        },
      },
    )

    setTimeout(() => {
      (window as any).Checkout.configure({
        merchant: mpgsGatewayConfigHook.merchantId,
        order: {
          description: formatMessage({ id: 'online.donation' }),
        },
        interaction: {
          merchant: {
            name: String(projectName || '').substr(0, 40),
          },
        },
        session: {
          id: sessionId,
        },
      })
      setLoading(false)
    }, 2000)
  }

  const requestCheckoutSession = async () => {
    const response = await axios.post(`/orders/${orderId}/mpgs_gateway/purchase`)
    setSessionId((get(response, 'data') || {})['session.id'])
  }

  useEffect(() => {
    if (sessionId) {
      injectMpgsScript(sessionId)
    }
  }, [sessionId])

  useEffect(() => {
    if (shouldLoad) {
      requestCheckoutSession()
    }
  }, [shouldLoad])

  console.log('merchantId', mpgsGatewayConfigHook.merchantId)
  console.log('password', mpgsGatewayConfigHook.password)
  console.log('paymentMethod', donationFormData.paymentMethod)
  console.log('sessionId', sessionId)

  if (!order || !currency || !isOneTimePayment || !isMpgs) {
    return <div />
  }

  if (done) {
    return (
      <PaidSuccessful />
    )
  }

  if (loading) {
    return (
      <PaymentLoading />
    )
  }

  return (
    <Button
      fullWidth
      variant='contained'
      color='secondary'
      onClick={() => (window as any).Checkout.showLightbox()}
    >
      <FormattedMessage id='pay.now' />
    </Button>
  )
}

export default OneTimeDonationMpgs
