import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ContributionSection from '../ContributionSection'
import PersonalInfoSection from '../PersonalInfoSection'
import StepTitle from '../StepTitle'
import TermsCheck from '../TermsCheck'

import styles from './style.module.scss'

const StepThree = () => {
  const { formatMessage } = useIntl()
  return (
    <div className={styles['section-three']}>

      <StepTitle index={3} title={formatMessage({ id: 'form.title.third' })} />

      <ContributionSection />

      <PersonalInfoSection />

      <TermsCheck />

      <div className={styles.statement}>
        <FormattedMessage id='statement.one' />
      </div>

      <div className={styles.statement}>
        <FormattedMessage id='statement.two' />
      </div>
    </div>
  )
}

export default StepThree
