import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

const FpsPaymentCard = ({ fpsBeneficiaryConfigs }) => {
  return (
    <div className={styles.card}>
      {
        fpsBeneficiaryConfigs.map((tmpFpsBeneficiaryConfig, idx) => {
          return (
            <div key={idx}>
              <div className={styles.item}>
                <span className={styles['item-name']}>
                  <FormattedMessage id='fps.code.title' />
                </span>
                <span className={styles['item-value']}>{tmpFpsBeneficiaryConfig?.fps_number}</span>
              </div>
              <div className={styles.item}>
                <span className={styles['item-name']}>
                  <FormattedMessage id='fps.beneficiary.title' />
                </span>
                <span className={styles['item-value']}>{tmpFpsBeneficiaryConfig?.payee_name}</span>
              </div>
              {fpsBeneficiaryConfigs.length - 1 !== idx && <div className={styles.break} />}
            </div>
          )
        })
      }
    </div>
  )
}

export default FpsPaymentCard
