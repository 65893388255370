import { get } from 'lodash'
import { axios } from 'qurtty-js-sdk'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { PayPalButton } from 'react-paypal-button-v2'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'
import useDonationFormFields from '../../hooks/useDonationFormFields'
import usePaypalGatewayConfig from '../../hooks/usePaypalGatewayConfig'
import PaymentLoading from '../DonateForm/components/PaymentLoading'
import PaidSuccessful from '../PaidSuccessful'

const OneTimeDonationPayPal = () => {
  const requestFormHook = useContext(RequestFormHookContext)
  const paypalGatewayConfigHook = usePaypalGatewayConfig()
  const order = requestFormHook.currentRequest.getOrder()
  const currency = get(order, 'currency')
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const price = get(order, 'price')
  const { formatMessage } = useIntl()
  const donationFormFieldsHook = useDonationFormFields()
  const donationFormData = donationFormFieldsHook.getFormData()
  const isOneTimePayment = donationFormData.donationType === 'One-Time Donation'
  const isPaypal = donationFormData.paymentMethod === formatMessage({ id: 'payment.method.paypal' })

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: currency,
            value: price,
          },
        },
      ],
    })
  }

  const onSuccess = async (details, data) => {
    setLoading(true)
    await axios.post(`/orders/${get(order, 'id')}/paypal_gateway/capture`, {
      ref_id: get(data, 'orderID'),
    })
    setDone(true)
    setLoading(false)
  }

  if (!order || !currency || !isOneTimePayment || !isPaypal) {
    return <div />
  }

  if (done) {
    return (
      <PaidSuccessful />
    )
  }

  if (loading) {
    return (
      <PaymentLoading />
    )
  }

  return (
    <PayPalButton
      style={{ shape: 'rect', color: 'gold', layout: 'vertical', label: 'pay' }}
      options={{ clientId: paypalGatewayConfigHook.clientId, currency, vault: true }}
      currency={currency}
      createOrder={createOrder}
      onSuccess={onSuccess}
    />
  )
}

export default OneTimeDonationPayPal
