import React from 'react'
import { useIntl } from 'react-intl'
import DonationTypeSelectionSection from '../DonationTypeSelectionSection'
import StepTitle from '../StepTitle'

const StepOne = () => {
  const { formatMessage } = useIntl()
  return (
    <div className='section-one'>
      <StepTitle
        index={1}
        title={formatMessage({ id: 'form.title.first' })}
      />

      <DonationTypeSelectionSection />
    </div>
  )
}

export default StepOne
