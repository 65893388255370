import { qurttyApiHelper, session } from 'qurtty-js-sdk'
import useFetchingState, { IUseFetchingStateHook } from './common/useFetchingState'

export interface IUseProjectHook {
  fetchProject: () => void
  project: any
  fetchProojectState: IUseFetchingStateHook
}

const useProject = () => {
  const projectKey = process.env.REACT_APP_PROJECT_ID
  const project = session.V3_Project.withId(projectKey)
  const fetchProojectState = useFetchingState()

  const fetchProject = async () => {
    try {
      fetchProojectState.markLoading()
      await qurttyApiHelper.getResources('eav/attr_types')
      await qurttyApiHelper.getResources('eav/particle_types')
      await qurttyApiHelper.getSingleResource('projects', projectKey, {
        includes: JSON.stringify({
          project_distributions: {},
          subscription_plans: {},
          request_groups: {},
          website_control: {
            website_nav_links: {},
            external_links: {},
            website_nav_groups: {},
            product_catalog_style: {},
            photo_catalog_style: {},
            card_catalog_style: {},
            blog_catalog_style: {},
            event_catalog_style: {},
            payment_gateway_config: {
              paypal_gateway_config: {},
              mpgs_gateway_config: {},
              fps_beneficiary_configs: {},
              bank_transfer_beneficiary_configs: {},
            },
          },
        }),
      })
      fetchProojectState.markSuccess()
    } catch (e) {
      fetchProojectState.markError(e)
    }
  }

  return {
    fetchProojectState,
    fetchProject,
    project,
  }
}

export default useProject
