import { FormControl, FormControlLabel, Grid, RadioGroup } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { get, snakeCase } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import WhiteRadio from '../../../../components/WhiteRadio'
import DonationHookContext from '../../../../contexts/DonationHookContext'
import useIsoLo from '../../../../hooks/useIsoLo'
import styles from './style.module.scss'

const ContributionSection = () => {
  const isoLo = useIsoLo()
  const { formatMessage } = useIntl()

  const { values, setValues, initialValues } = useFormikContext()
  const donationHook = useContext(DonationHookContext)

  const donationAmount = get(values, 'donation_amount')
  const isCustomAmount = !String(donationAmount || '').startsWith('$')

  useEffect(() => {
    if (!isCustomAmount) {
      setValues({
        ...values as any,
        custom_amount: get(initialValues, 'custom_amount'),
      })
    }
  }, [isCustomAmount])

  return (
    <div className={styles['contribution-section']}>
      <div className={styles.sub}>{formatMessage({ id: 'form.third.amount.title' })}</div>
      <div className={styles['amount-selection']}>
        <FormControl component='fieldset'>
          <div className={styles.sub}>
            <FormattedMessage id={`form.third.${donationHook.isMonthlyDonation ? 'monthly' : 'single'}.amount.subtitle`} />
          </div>
          <Field name='donation_amount'>
            {({ field: { onChange, name, value } }) => (
              <RadioGroup
                aria-label='donate plan amount'
                className={styles['plan-group']}
                name={name}
                value={value}
                onChange={onChange}
              >
                <Grid container spacing={1}>
                  {donationHook.donationAmounts.map((amount: any, index: number) => (
                    <Grid key={index} item xs={6} md={3}>
                      <FormControlLabel
                        label={amount[`name_${snakeCase(isoLo)}`]}
                        value={amount.name_en}
                        className={styles['plan-block'] + ' ' + (value === amount.name_en ? styles['plan-block-selected'] : '')}
                        control={<WhiteRadio />}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            )}
          </Field>
        </FormControl>
      </div>
    </div>
  )
}

export default ContributionSection
