import { Form, Formik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import DonateForm from '../../components/DonateForm'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'

const FormPage = () => {
  const { lo } = useParams<any>()
  const history = useHistory()
  const requestFormHook = useContext(RequestFormHookContext)

  const initialValues = {
    donation_type: 'Monthly Donation',
    donation_service: 'Elderly Services',
    donation_amount: '$195',
    donor_type: 'individual',
    title: 'mr',
    payment_method: 0,
    age_range: 0,
    from_source: 0,
    custom_amount: 10,
    receipt_needed: false,
    contact_language: 'zh-HK',
    no_promotion: false,
    accept_tnc: false,
  }

  const onSubmit = (values) => {
    requestFormHook.setFormValues(values)
    history.replace(`/${lo}/confirm`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form>
          <DonateForm />
        </Form>
      </Formik>
    </div>
  )
}

export default FormPage
