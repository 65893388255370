import { get } from 'lodash'
import usePaypalGatewayConfig from './usePaypalGatewayConfig'

const usePaypalBillingPlanDatum = (paypalBillingPlanDatum) => {
  const paypalGatewayConfigHook = usePaypalGatewayConfig()
  const enabled = get(paypalBillingPlanDatum, 'enabled')
  const productionPaypalPlanId = get(paypalBillingPlanDatum, 'production_paypal_plan_id')
  const sandboxPaypalPlanId = get(paypalBillingPlanDatum, 'sandbox_paypal_plan_id')
  const paypalPlanId = paypalGatewayConfigHook.isSandboxMode ? sandboxPaypalPlanId : productionPaypalPlanId

  return {
    enabled,
    productionPaypalPlanId,
    sandboxPaypalPlanId,
    paypalPlanId,
  }
}

export default usePaypalBillingPlanDatum
