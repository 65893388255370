import { useContext } from 'react'
import ProjectHookContext from '../contexts/ProjectHookContext'
import useFetchingState from './common/useFetchingState'

const useHostedProducts = () => {
  const projectHook = useContext(ProjectHookContext)
  const fetchHostedProductsState = useFetchingState()
  const hostedProducts = projectHook.project ? projectHook.project.getHostedProducts() : []

  const fetchHostedProducts = async () => {
    try {
      fetchHostedProductsState.markLoading()
      await projectHook.project.$refreshAssociation('products', {
        includes: JSON.stringify({ billing_plans: { paypal_billing_plan_datum: {} }, subproduct_groups: { product_groupings: {} }, photos: {} }),
      })
      fetchHostedProductsState.markSuccess()
    } catch (e) {
      fetchHostedProductsState.markError(e)
    }
  }

  return {
    hostedProducts,
    fetchHostedProducts,
    fetchHostedProductsState,
  }
}

export default useHostedProducts
