import { makeStyles, Radio, RadioProps } from '@material-ui/core'

const WhiteRadio: React.FunctionComponent<RadioProps> = (props) => {
  const classes = useStyles()

  return (
    <Radio
      {...props}
      classes={classes}
      color='default'
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#000',
    '&$checked': {
      color: '#fff',
    },
  },
  checked: {},
}))

export default WhiteRadio
