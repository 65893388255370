import { get } from 'lodash'
import { useContext } from 'react'
import ProjectHookContext from '../contexts/ProjectHookContext'
import useFetchingState from './common/useFetchingState'

const useRequestGroups = () => {
  const projectHook = useContext(ProjectHookContext)
  const websiteControl = get(projectHook.project, 'website_control')
  const requestGroups = typeof get(projectHook.project, 'request_groups.toModelArray') === 'function' ? projectHook.project.request_groups.toModelArray() : []
  const fetchRequestGroupsState = useFetchingState()
  const currentRequestGroup = requestGroups.find((rg) => get(rg, 'id') === Number(process.env.REACT_APP_REQUEST_GROUP_ID))

  const fetchRequestGroups = async () => {
    try {
      fetchRequestGroupsState.markLoading()
      await projectHook.project.$refreshAssociation('request_groups', { cache_timestamp: websiteControl.cache_timestamp })
      fetchRequestGroupsState.markSuccess()
    } catch (e) {
      fetchRequestGroupsState.markError(e)
    }
  }

  return {
    requestGroups,
    fetchRequestGroups,
    fetchRequestGroupsState,
    currentRequestGroup,
  }
}

export default useRequestGroups
