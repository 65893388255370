import { Button } from '@material-ui/core'
import { useFormikContext } from 'formik'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import DonationHookContext from '../../contexts/DonationHookContext'
import useDonation from '../../hooks/useDonation'
import StepOne from './components/StepOne'
import StepThree from './components/StepThree'
import StepTwo from './components/StepTwo'
import styles from './style.module.scss'

const DonateForm = () => {
  const { values } = useFormikContext()
  const donationHook = useDonation(values)

  const renderFormContent = () => (
    <div id={styles['donate-form']}>

      <StepOne />

      <StepTwo />

      <StepThree />

      <div className={styles['submit-button-container']}>
        <Button
          disableElevation
          type='submit'
          color='primary'
          variant='contained'
          className={styles['submit-button']}
          style={{ fontWeight: 'bold' }}
        >
          <FormattedMessage id='form.submit.button' />
        </Button>
      </div>

    </div>
  )

  return (
    <DonationHookContext.Provider value={donationHook}>
      {renderFormContent()}
    </DonationHookContext.Provider>
  )
}

export default DonateForm
