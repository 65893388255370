import { makeStyles, Typography } from '@material-ui/core'
import { green, grey } from '@material-ui/core/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const PaidSuccessful = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CheckCircleIcon className={classes.tick} />
      <Typography className={classes.text} variant='h4'>
        <FormattedMessage id='paid.successful' />
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  text: {
    color: grey[800],
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  tick: {
    color: green[500],
    margin: theme.spacing(1),
    fontSize: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
}))

export default PaidSuccessful
