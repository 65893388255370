import { get } from 'lodash'
import React, { useEffect } from 'react'
import Favicon from 'react-favicon'
import { IntlProvider } from 'react-intl'
import { MetaTags, ReactTitle } from 'react-meta-tags'
import Main from './Main'
import lang from './constants/lang'
import ProjectHookContext from './contexts/ProjectHookContext'
import useIsoLo from './hooks/useIsoLo'
import useProject from './hooks/useProject'
import MaterialProvider from './utils/MaterialProvider'

const App = () => {
  const isoLo = useIsoLo()
  const projectHook = useProject()
  const messages = get(lang, isoLo)

  const themeColor = get(projectHook, 'project.website_control.theme_color')
  const projectName = get(projectHook, 'project.name')
  const projectCoverUrl = get(projectHook, 'project._cover.url')

  useEffect(() => {
    projectHook.fetchProject()
  }, [])

  return (
    <MaterialProvider>
      <MetaTags>
        <ReactTitle title={projectName} />
        <meta name='theme-color' content={themeColor} />
      </MetaTags>
      <Favicon url={projectCoverUrl} />
      <IntlProvider messages={messages} locale={isoLo || 'en'}>
        <ProjectHookContext.Provider value={projectHook}>
          <Main />
        </ProjectHookContext.Provider>
      </IntlProvider>
    </MaterialProvider>
  )
}

export default App
