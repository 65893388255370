import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { first, get } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import FpsImg from '../../../../assets/images/fps.png'
import JCBImg from '../../../../assets/images/jcb.png'
import MasterCardImg from '../../../../assets/images/mastercard.png'
import PaypalImg from '../../../../assets/images/paypal.png'
import UnionPayImg from '../../../../assets/images/unionpay.png'
import VisaImg from '../../../../assets/images/visa.png'
import AGES_TYPES from '../../../../constants/AGES_TYPES'
import WAYS_TO_KNOW_DONATE_ONLINE from '../../../../constants/WAYS_TO_KNOW_DONATE_ONLINE'
import DonationHookContext from '../../../../contexts/DonationHookContext'
import ProjectHookContext from '../../../../contexts/ProjectHookContext'
import useIsoLo from '../../../../hooks/useIsoLo'
import BankTransferCard from '../BankTranferCard'
import FpsPaymentCard from '../FpsPaymentCard'
import OrganizationForm from '../OrganizationForm'
import PersonalForm from '../PersonalForm'
import styles from './style.module.scss'

const PersonalInfoSection = () => {
  const { formatMessage } = useIntl()
  const isoLo = useIsoLo()
  const { values, setFieldValue, setValues, initialValues } = useFormikContext()
  const donationHook = useContext(DonationHookContext)

  const projectHook = useContext(ProjectHookContext)
  const paymentGatewayConfig = get(projectHook.project, 'website_control.payment_gateway_config')
  const bankTransferBeneficiaryConfigs = paymentGatewayConfig.bank_transfer_beneficiary_configs.toModelArray()
  const fpsBeneficiaryConfigs = paymentGatewayConfig.fps_beneficiary_configs.toModelArray()

  const donationType = get(values, 'donation_type')
  const donorType = get(values, 'donor_type')
  const paymentMethod = get(values, 'payment_method')
  const receiptNeeded = get(values, 'receipt_needed')
  const donationAmount = get(values, 'donation_amount')
  const needAttachReceipt = [1, 2].includes(paymentMethod)

  const isMonthly = donationHook.isMonthlyDonation
  const isCustomAmount = !String(donationAmount || '').startsWith('$')

  const [receiptFileValue, setReceiptFileValue] = useState<any>(null)

  const ageTypes = AGES_TYPES(isoLo)
  const waysToKnowDonateOnline = WAYS_TO_KNOW_DONATE_ONLINE(isoLo)

  const onNumberChange = (form, fieldName: string) => ({ target: { value } }) => {
    form.setFieldValue(fieldName, Number(value))
  }

  const onBooleanChange = (form, fieldName: string) => ({ target: { value } }) => {
    form.setFieldValue(fieldName, value === 'true')
  }

  useEffect(() => {
    setFieldValue('payment_method', 0)
  }, [donationType])

  useEffect(() => {
    if (donorType === 'individual') {
      setFieldValue('organization_name', null)
    } else {
      setValues({
        ...values as any,
        title: (initialValues as any).title,
        firstname: null,
        lastname: null,
      })
    }
  }, [donorType])

  useEffect(() => {
    if (!receiptNeeded) {
      setFieldValue('receipt_name', null)
    }
  }, [receiptNeeded])

  useEffect(() => {
    if (!needAttachReceipt) {
      setReceiptFileValue(null)
      setFieldValue('receipt_file', null)
    }
  }, [needAttachReceipt])

  return (
    <div className={styles['personal-info-section']}>
      <div className={styles.sub}>
        <FormattedMessage id='form.third.info.title' />
      </div>
      <div className={styles['input-form']}>
        <div className={styles['donor-type-selection']}>
          <FormControl>
            <div className={styles.sub + ' ' + styles.warning}>
              <FormattedMessage id='form.mandatory.fields' />
            </div>

            <Field name='donor_type'>
              {({ field: { onChange, name, value } }) => (
                <RadioGroup
                  aria-label='donor type'
                  className={styles['plan-group']}
                  name={name}
                  value={value}
                  onChange={onChange}
                >
                  <FormControlLabel
                    value='individual'
                    control={<Radio />}
                    label={formatMessage({ id: 'donation.donor.type.individual' })}
                  />

                  <FormControlLabel
                    value='organization'
                    control={<Radio />}
                    label={formatMessage({ id: 'donation.donor.type.organization' })}
                  />
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        </div>

        <Field name='donor_no'>
          {({ field: { onChange, name, value } }) => (
            <TextField
              name={name}
              value={value || ''}
              margin='dense'
              id='standard-basics'
              className={styles['text-input']}
              onChange={onChange}
              label={formatMessage({ id: 'form.donor.no' })}
              placeholder={formatMessage({ id: 'form.donor.no.placeholder' })}
            />
          )}
        </Field>

        {donorType === 'individual' ? (
          <PersonalForm />
        ) : (
          <OrganizationForm />
        )}

        <Field name='tel'>
          {({ field: { onChange, name, value } }) => (
            <TextField
              margin='dense'
              className={styles['text-input']}
              name={name}
              onChange={onChange}
              value={value || ''}
              label={formatMessage({ id: 'form.tel.no' })}
              placeholder={formatMessage({ id: 'form.tel.no' })}
              required
            />
          )}
        </Field>

        <Field name='email'>
          {({ field: { onChange, name, value } }) => (
            <TextField
              type='email'
              margin='dense'
              className={styles['text-input']}
              name={name}
              value={value || ''}
              onChange={onChange}
              label={formatMessage({ id: 'form.email.address' })}
              placeholder={formatMessage({ id: 'form.email.address' })} />
          )}
        </Field>

        {donorType === 'individual' && (
          <Field name='age_range'>
            {({ field: { name, value, onChange } }) => (
              <TextField
                select
                margin='dense'
                className={styles['text-input'] + ' ' + styles['margin-top-1']}
                label={formatMessage({ id: 'form.age.range' })}
                value={value || 0}
                name={name}
                onChange={onChange}
              >
                {ageTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
        )}

        <Field name='from_source'>
          {({ field: { onChange, name, value } }) => (
            <TextField
              select
              name={name}
              value={value}
              onChange={onChange}
              className={styles['text-input'] + ' ' + styles['margin-top-1']}
              label={formatMessage({ id: 'form.source' })}
            >
              {waysToKnowDonateOnline.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Field>

        {!isMonthly && (
          <FormControl component='fieldset' required>
            <div className={styles['radio-group-label'] + ' ' + styles.required}>
              <FormattedMessage id='form.payment.method' />
            </div>

            <Field name='payment_method'>
              {({ field: { name, value }, form }) => (
                <RadioGroup
                  name={name}
                  value={value}
                  aria-label={name}
                  onChange={onNumberChange(form, 'payment_method')}
                >
                  <Grid container>

                    <FormControlLabel
                      control={
                        <div className={styles['online-payment-option']}>
                          <Radio value={0} />
                          <span className={styles['payment-group']}>
                            <img className={styles['paypal-logo']} src={PaypalImg} />
                          </span>
                        </div>
                      }
                      label=''
                    />

                    <FormControlLabel
                      control={
                        <div className={styles['online-payment-option']}>
                          <Radio value={3} />
                          <span className={styles['payment-group']}>
                            <img className={styles['payment-logo']} src={VisaImg} alt='visa logo' />
                            <img className={styles['payment-logo']} src={MasterCardImg} alt='mastercard logo' />
                            <img className={styles['payment-logo']} src={JCBImg} alt='jcb logo' />
                            <img className={styles['payment-logo']} src={UnionPayImg} alt='unionPay logo' />
                          </span>
                        </div>
                      }
                      label=''
                    />

                    {
                      fpsBeneficiaryConfigs.some((tmpFpsBeneficiaryConfig) => tmpFpsBeneficiaryConfig.enabled) && (
                        <FormControlLabel
                          control={
                            <div className={styles['online-payment-option']}>
                              <Radio value={1} />
                              <span className={styles['payment-group']}>
                                <img className={styles['payment-logo']} src={FpsImg} alt='fps logo' />
                              </span>
                            </div>
                          }
                          label={formatMessage({ id: 'payment.method.fps' })}
                        />
                      )
                    }

                    {
                      bankTransferBeneficiaryConfigs.some((tmpBankTransferBeneficiaryConfig) => tmpBankTransferBeneficiaryConfig.enabled) && (
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label={formatMessage({ id: 'payment.method.bank.transfer' })}
                        />
                      )
                    }
                  </Grid>
                </RadioGroup>
              )}
            </Field>

          </FormControl>
        )}

        {!isMonthly && paymentMethod === 1 && <FpsPaymentCard fpsBeneficiaryConfigs={fpsBeneficiaryConfigs.filter((tmpFpsBeneficiaryConfig) => tmpFpsBeneficiaryConfig.enabled)} />}
        { !isMonthly && paymentMethod === 2 && <BankTransferCard bankTransferBeneficiaryConfigs={bankTransferBeneficiaryConfigs.filter((tmpBankTransferBeneficiaryConfig) => tmpBankTransferBeneficiaryConfig.enabled)} /> }

        <FormControl component='fieldset'>
          <div className={styles['radio-group-label']}>
            <FormattedMessage id='form.receipt' />
          </div>

          <Field name='receipt_needed'>
            {({ field: { name, value }, form }) => (
              <RadioGroup
                name={name}
                value={value}
                aria-label={name}
                onChange={onBooleanChange(form, 'receipt_needed')}
              >
                <Grid container>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={formatMessage({ id: 'receipt.needed' })}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={formatMessage({ id: 'receipt.no.needed' })}
                  />
                </Grid>
              </RadioGroup>
            )}
          </Field>
        </FormControl>

        {receiptNeeded && (
          <Field name='receipt_name'>
            {({ field: { name, value, onChange } }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                className={styles['text-input']}
                label={formatMessage({ id: 'form.receipt.name' })}
                placeholder={formatMessage({ id: 'form.textfield.limit' }, { min: 2, max: 30 })}
              />
            )}
          </Field>
        )}

        {needAttachReceipt && (
          <Field type='file' name='receipt_file'>
            {({ field: { name, value }, form }) => {
              const onChange = async ({ target }) => {
                const file = first(target.files)
                setReceiptFileValue(target.value)

                if (file) {
                  form.setFieldValue('receipt_file', file)
                } else {
                  form.setFieldValue('receipt_file', null)
                }
              }

              return (
                <div className={styles['file-upload-section']}>
                  <span className={styles.required}>
                    <FormattedMessage id='form.receipt.upload' />
                  </span>

                  <div className={styles['file-upload-button']}>
                    <input
                      required
                      type='file'
                      name={name}
                      onChange={onChange}
                      value={receiptFileValue}
                      id='contained-button-file'
                    />
                  </div>
                </div>
              )
            }}
          </Field>
        )}

        {isCustomAmount && (
          <Field type='number' name='custom_amount'>
            {({ field: { onChange, name, value } }) => {
              const onBlur = ({ target: { value } }) => {
                if (value < 10) {
                  setFieldValue(name, 10)
                }
              }

              return (
                <TextField
                  type='number'
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className={styles['text-input'] + ' ' + styles['margin-top-1']}
                  label={formatMessage({ id: 'custom.amount' })}
                  inputProps={{ min: 10 }}
                />
              )
            }}
          </Field>
        )}

        <FormControl component='fieldset'>
          <div className={styles['radio-group-label']}>
            <FormattedMessage id='form.language' />
          </div>

          <Field name='contact_language'>
            {({ field: { name, value, onChange } }) => (
              <RadioGroup
                name={name}
                value={value}
                aria-label={name}
                onChange={onChange}
              >
                <Grid container>
                  <FormControlLabel
                    value='zh-HK'
                    control={<Radio />}
                    label={formatMessage({ id: 'language.zh' })}
                  />
                  <FormControlLabel
                    value='en'
                    control={<Radio />}
                    label={formatMessage({ id: 'language.en' })}
                  />
                </Grid>
              </RadioGroup>
            )}
          </Field>
        </FormControl>

      </div>
    </div >
  )
}

export default PersonalInfoSection
