import { Checkbox, Link } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

const TermsCheck = () => {
  return (
    <div id={styles['terms-check']}>

      <Field type='checkbox' name='accept_tnc' value={true}>
        {({ field: { onChange, name, value } }) => (
          <div className={styles.term}>
            <Checkbox
              required
              name={name}
              value={value}
              onChange={onChange}
              className={styles.checkbox}
              color='primary'
            />
            <span>
              <FormattedMessage
                id='terms.first'
                values={{
                  link: (
                    <Link href={process.env.REACT_APP_TNC_URL} target='_blank' color='primary'>
                      <FormattedMessage id='info.statement.title' />
                    </Link>
                  ),
                }}
              />
            </span>
          </div>
        )}
      </Field>

      <Field type='checkbox' name='no_promotion' value={true}>
        {({ field: { onChange, name, value } }) => (
          <div className={styles.term}>
            <Checkbox
              name={name}
              value={value}
              onChange={onChange}
              className={styles.checkbox}
              color='primary'
            />
            <span>
              <FormattedMessage id='terms.second' />
            </span>
          </div>
        )}
      </Field>
    </div>
  )
}

export default TermsCheck
