import { snakeCase } from 'lodash'
import { useContext } from 'react'
import HostedProductsHookContext from '../contexts/HostedProductsHookContext'
import useDonationFormFields from './useDonationFormFields'
import useIsoLo from './useIsoLo'

const useDonation = (donationDataSource?: any) => {
  const isoLo = useIsoLo()
  const hostedProductsHook = useContext(HostedProductsHookContext)

  const donationFormFieldsHook = useDonationFormFields()

  const monthlyDonation = findProductByName('Monthly Donation', 'en')
  const oneTimeDonation = findProductByName('One-Time Donation', 'en')

  const monthlyDonationSubGroups = monthlyDonation.subproduct_groups.toModelArray()
  const monthlyDonationAmountGroupings = monthlyDonationSubGroups.find((group) => group.name_en === 'Amount').product_groupings.orderBy('display_order').toModelArray()
  const monthlyDonationAmountProducts = monthlyDonationAmountGroupings.map((productGrouping) => productGrouping.product).sort()
  const monthlyDonationServiceGroupings = monthlyDonationSubGroups.find((group) => group.name_en === 'Services').product_groupings.orderBy('display_order').toModelArray()
  const monthlyDonationServiceProducts = monthlyDonationServiceGroupings.map((productGrouping) => productGrouping.product)

  const oneTimeDonationSubGroups = oneTimeDonation.subproduct_groups.toModelArray()
  const oneTimeDonationAmountGroupings = oneTimeDonationSubGroups.find((group) => group.name_en === 'Amount').product_groupings.orderBy('display_order').toModelArray()
  const oneTimeDonationAmountProducts = oneTimeDonationAmountGroupings.map((productGrouping) => productGrouping.product).sort()
  const oneTimeDonationServicesGroupings = oneTimeDonationSubGroups.find((group) => group.name_en === 'Services').product_groupings.orderBy('display_order').toModelArray()
  const oneTimeDonationServiceProducts = oneTimeDonationServicesGroupings.map((productGrouping) => productGrouping.product)

  const isMonthlyDonation = donationFormFieldsHook.getFormData(donationDataSource).donationType === 'Monthly Donation'

  const donationTypes = [monthlyDonation, oneTimeDonation]
  const donationAmounts = isMonthlyDonation ? monthlyDonationAmountProducts : oneTimeDonationAmountProducts
  const donationServices = isMonthlyDonation ? monthlyDonationServiceProducts : oneTimeDonationServiceProducts

  function findProductByName (name, lo?: string) {
    return hostedProductsHook.hostedProducts.find((pdct) => pdct[`name_${snakeCase(lo || isoLo)}`] === name)
  }

  return {
    isMonthlyDonation,
    monthlyDonation,
    oneTimeDonation,
    monthlyDonationAmountGroupings,
    monthlyDonationAmountProducts,
    monthlyDonationServiceGroupings,
    monthlyDonationServiceProducts,
    oneTimeDonationAmountGroupings,
    oneTimeDonationAmountProducts,
    oneTimeDonationServicesGroupings,
    oneTimeDonationServiceProducts,
    donationTypes,
    donationAmounts,
    donationServices,
  }
}

export default useDonation
