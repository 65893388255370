import { Button, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router'
import locales from '../../constants/locales'
import useIsoLo from '../../hooks/useIsoLo'

const SwitchLocaleButton = () => {
  const isoLo = useIsoLo()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const onClick = () => {
    const newLo = locales[(locales.indexOf(isoLo) + 1) % locales.length]
    history.push(location.pathname.replace(`/${isoLo}`, `/${newLo}`))
  }

  return (
    <Button
      disableElevation
      onClick={onClick}
      size='small'
      variant='contained'
      className={classes.root}
    >
      <FormattedMessage id='locale' />
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    background: `${blueGrey[700]} !important`,
    color: 'white',
  },
}))

export default SwitchLocaleButton
