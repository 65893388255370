import { TextField } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import styles from './style.module.scss'

const OrganizationForm = () => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <div className={styles.form}>

        <Field name='organization_name'>
          {({ field: { onChange, name, value } }) => (
            <TextField
              required
              margin='dense'
              name={name}
              value={value}
              onChange={onChange}
              className={styles['text-input']}
              label={formatMessage({ id: 'form.organization.name' })}
              placeholder={formatMessage({ id: 'form.organization.name' })}
            />
          )}
        </Field>

      </div>
    </div>
  )
}

export default OrganizationForm
