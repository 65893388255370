import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { grey, pink } from '@material-ui/core/colors'
import { ReactNode } from 'react'
import defaultFontFamily from '../constants/defaultFontFamily'

interface IProps {
  children: ReactNode
  // any other props that come into the component
}

const MaterialProvider = ({ children }: IProps) => {
  const theme = createMuiTheme({
    palette: {
      primary: pink,
      secondary: grey,
    },
    typography: {
      fontFamily: defaultFontFamily,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default MaterialProvider
