import { get } from 'lodash'

export const getBasename = (url = null) => {
  return get(/\/(m|c)\/[^/]+\/q-project\/[^/]+/.exec(url || window.location.href), '[0]') || ''
}

export const isLocal = () => ['localhost', '127.0.0.1', ''].includes(window.location.hostname)

export const getProjectSlug = () => {
  const url = window.location.href
  const projectKey = get(/\/(m|c)\/[^/]+\/q-project\/([^/]+)/.exec(url), '[2]')

  if (!projectKey) {
    return new URLSearchParams(window.location.search).get('project_key')
  }
  if (!isLocal()) {
    return projectKey || window.location.host.replace(/^www./, '')
  }

  return projectKey
}
