import lang from './lang'

export default (locale) => {
  return [
    lang[locale]['payment.method.paypal'],
    lang[locale]['payment.method.fps'],
    lang[locale]['payment.method.bank.transfer'],
    lang[locale]['payment.method.mpgs'],
  ]
}
