import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const PaymentLoading = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress thickness={5} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default PaymentLoading
