const lang = {
  en: {
    locale: 'ENG',
    'online.donation': 'Online Donation',
    'online.donation.subtitle1': 'Charitable Institutions: REF.CP/LIC/SO/19/53510',
    'online.donation.subtitle2': 'Tax exemption number︰91/14252',
    'online.donation.link': 'More Details',
    'form.title.index': 'Step',
    'form.title.first': 'SELECT DONATION TYPE',
    'form.type.selection.subtitle': 'Please tick the checkbox:',
    'donation.plan.detail.title': 'Project Detail',
    'form.title.second': 'SELECT PROJECT',
    'form.title.third': 'ENTER PERSONAL PARTICULARS',
    'form.third.amount.title': 'My Contribution',
    'form.third.monthly.amount.subtitle': 'Monthly Donation(HK$)',
    'form.third.single.amount.subtitle': 'One-Time Donation (HK$)',
    'form.third.info.title': "Donor's Information",
    'form.mandatory.fields': 'Mandatory Fields',
    'form.donor.no': 'Donor No.',
    'form.donor.title': 'Title',
    'form.surname': 'Surname',
    'form.given.name': 'Given Name',
    'form.tel.no': 'Contact Tel. No.',
    'form.email.address': 'Email Address',
    'form.age.range': 'Age Range',
    'form.source': 'Source of knowing online donation',
    'form.payment.method': 'Payment method',
    'form.receipt': 'Receipt is needed',
    'form.receipt.name': 'Name on Receipt',
    'form.receipt.upload': 'Upload FPS/Bank receipts',
    'form.language': 'Correspondence Language',
    'form.organization.name': 'Company Name',
    'form.submit.button': 'Next',

    'form.donor.no.placeholder': '(if applicable)',
    'form.textfield.limit': '{min}~{max} character',

    'donation.plan': 'Donation Plan',
    'donation.service': 'Donation Service',
    'donation.amount': 'Donation Amount (HKD)',

    'plan.monthly.title': 'Monthly Donation',
    'plan.monthly.detail': 'The monthly donation provides stable support to Pok Oi, which allows to develop the long-term projects, by making good use of every cent to help more vulnerable groups in need.',
    'plan.single.title': 'One-Time Donation',
    'plan.single.detail': "Pok Oi 『 The Centre of Philanthropy 』 cherishes your every donation. Your donation helps the organisation's development in order to have provision of guidance, training and family support, thereby contributing to an inclusive society.",

    'plan.detail.first.title': 'Elderly Services',
    'plan.detail.first.detail': 'Provide assistance to the elderly in need and develop their welfare services',
    'plan.detail.second.title': 'Medical subsidies',
    'plan.detail.second.detail': 'Provide medical subsidies for children, elders and the weak groups',
    'plan.detail.third.title': 'Family services',
    'plan.detail.third.detail': 'Support for lower-income, and single-parent families',

    'donation.amount.other': 'Custom',

    'donation.donor.type': 'Donor Type',
    'donation.donor.type.individual': 'Individual',
    'donation.donor.type.organization': 'Company / Organization',

    'donor.title.mr': 'Mr',
    'donor.title.ms': 'Ms',
    'donor.title.mrs': 'Mrs',
    'donor.title.miss': 'Miss',

    'age.range.above': 'above {age}',

    'source.internet': 'Internet',
    'source.newspaper': 'Newspaper',
    'source.radio': 'Radio',
    'source.tv': 'TV',
    'source.direct.mailing': 'Direct mailing',
    'source.newsletter': 'Newsletter',
    'source.our.website': 'Our Website',
    'source.our.email': 'Email',
    'source.our.friends': 'Friends',
    'source.our.street.fundraisers': 'Street Fundraisers',
    'source.our.street.telemarketing.fundraisers': 'Telemarketing Fundraisers',
    'source.our.street.others': 'Others',

    'payment.method.fps': 'Faster Payment System(FPS)',
    'payment.method.bank.transfer': 'Bank transfer',

    'receipt.needed': 'E-receipt',
    'receipt.no.needed': 'No receipt',

    'language.zh': 'Chinese',
    'language.en': 'English',

    'file.upload.button.text': 'Select document',

    'info.statement.title': 'the Personal Information Collection Statement',
    'terms.first': 'I have read and understood {link}. We cannot use your personal data without your prior consent for the purposes of providing you with marketing materials. By clicking ‘NEXT’ to proceed the donation process, you indicate your agreement to the use of your name and contact information for direct marketing purposes in accordance with paragraph 4 in the Personal Information Collection Statement , unless you have ticked the box below indicating that you object to such use.',
    'terms.second': 'I do NOT wish to receive any information or materials regarding campaigns, activities or fundraising appeals hosted or administered',

    'fps.code.title': 'Faster Payment System(FPS) code',
    'fps.beneficiary.title': 'Beneficiary:',
    'fps.qrcode.title': 'FPS QR Code:',

    'bank.transfer.bank.name.title': 'Bank name:',
    'bank.transfer.bank.account.title': 'Bank account:',
    'bank.transfer.beneficiary.title': 'Beneficiary:',

    'contact_language.en': 'English',
    'contact_language.zh_hk': 'Chinese',

    'no.file.chosen': 'No file chosen',

    'statement.one': '* We will temporarily deduct an amount equivalent to the monthly donation from your credit card account to verify your account information. These deductions are not actual payments, and the time required will depend on each bank. Actual donations will be posted on the 10th of each month.',
    'statement.two': '* At the end of each financial year on March of 31st, we would prepare annual receipts to members on a monthly donation, which will be sending to the member’s email from April to May every year. If it’s a one-off donation, member will acquire an official receipt within 6 working weeks.',

    'payment.method.paypal': 'PayPal',
    'payment.method.mpgs': 'MPGS',

    'receipt.selection': 'Receipt selection',

    'no.promotion': 'Promotion Message',
    'no.promotion.yes': 'Yes',
    'no.promotion.no': 'No',

    'custom.amount': 'Customized amount (HKD)',
    'paid.successful': 'Thank you for your donation',

    'donation.info': 'Donation Info',

    'pay.now': 'Pay Now',
  },
  'zh-HK': {
    locale: '繁體',
    'online.donation': '網上捐款',
    'online.donation.subtitle1': '香港慈善牌照編號: REF.CP/LIC/SO/19/53510',
    'online.donation.subtitle2': '獲豁稅免繳編號︰91/14252',
    'online.donation.link': '了解更多',
    'form.title.index': '步驟',
    'form.title.first': '選擇捐款類型',
    'form.type.selection.subtitle': '請選擇:',
    'donation.plan.detail.title': '計劃詳情',
    'form.title.second': '選擇計劃',
    'form.title.third': '輸入個人資料',
    'form.third.amount.title': '捐款金額',
    'form.third.monthly.amount.subtitle': '每月捐款（港幣）',
    'form.third.single.amount.subtitle': '單次捐款（港幣）',
    'form.third.info.title': '捐款人資料',
    'form.mandatory.fields': '必須填寫',
    'form.donor.no': '捐款人號碼',
    'form.donor.title': '稱謂',
    'form.surname': '姓氏',
    'form.given.name': '名字',
    'form.tel.no': '聯絡電話號碼',
    'form.email.address': '電郵地址',
    'form.age.range': '年齡層',
    'form.source': '得悉網上捐款途徑',
    'form.payment.method': '付款方法',
    'form.receipt.upload': '上載 轉數快/銀行轉帳 收據',
    'form.receipt': '收據選擇',
    'form.receipt.name': '收據上之姓名',
    'form.language': '通訊語言',
    'form.organization.name': '公司/機構名稱',
    'form.submit.button': '下一步',

    'form.donor.no.placeholder': '（曾捐款人適用）',
    'form.textfield.limit': '{min}~{max} 字符',

    'donation.plan': '捐款計劃',
    'donation.service': '計劃詳情',
    'donation.amount': '捐款 (港幣)',

    'plan.monthly.title': '每月捐款計劃',
    'plan.monthly.detail': '每月捐款提供了穩定的支持, 讓博愛堂服務中心可以計劃長遠的發展項目- 讓我們善用一分一毫幫助更多有需要的弱勢社群。',
    'plan.single.title': '單次捐款',
    'plan.single.detail': '博愛堂服務中心會珍惜您每一分一毫的捐獻，您的慷慨捐助，能支持本會推動社區發展工作和救援, 令弱勢社群受益。',

    'plan.detail.first.title': '老人服務',
    'plan.detail.first.detail': '為有需要長者提供生活上的援助, 拓展長者福利服務',
    'plan.detail.second.title': '醫療補貼',
    'plan.detail.second.detail': '資助病患兒童、長者及有需要基層的醫療上的開支輔助',
    'plan.detail.third.title': '家庭服務',
    'plan.detail.third.detail': '給予低收入、單親家庭在生活上的支援',

    'donation.amount.other': '自訂',

    'donation.donor.type': '身份',
    'donation.donor.type.individual': '個人捐款',
    'donation.donor.type.organization': '機構／公司',

    'donor.title.mr': '先生',
    'donor.title.ms': '女士',
    'donor.title.mrs': '太太',
    'donor.title.miss': '小姐',

    'age.range.above': '{age} 以上',

    'source.internet': '互聯網',
    'source.newspaper': '報紙',
    'source.radio': '電台',
    'source.tv': '電視',
    'source.direct.mailing': '直銷函件',
    'source.newsletter': '本會通訊',
    'source.our.website': '本會網頁',
    'source.our.email': '電郵',
    'source.our.friends': '朋友',
    'source.our.street.fundraisers': '街頭籌募大使',
    'source.our.street.telemarketing.fundraisers': '電話籌募員',
    'source.our.street.others': '其他',

    'payment.method.paypal': 'PayPal',
    'payment.method.mpgs': 'MPGS',
    'payment.method.fps': '轉數快(FPS)',
    'payment.method.bank.transfer': '銀行轉帳',

    'receipt.selection': '收據選擇',
    'receipt.needed': '需要電子收據',
    'receipt.no.needed': '毋需發收據',

    'language.zh': '中文',
    'language.en': '英文',

    'file.upload.button.text': '選擇檔案',

    'info.statement.title': '個人資料收集聲明',
    'terms.first': '我已閱讀 「 {link} 」，並清楚明白其內容.未經您事先同意，本會不會使用您的個人資料作推廣用途。若您按「下一步」繼續捐款，即表示您同意本會「個人資料收集聲明」第4段，將您的姓名及聯絡資料用於直接推廣。若您不同意，請在以下方格加上剔號：',
    'terms.second': '我不希望收取與「博愛堂服務中心」的任何計劃及活動資訊或籌募呼籲。',
    'no.promotion': '推廣訊息',
    'no.promotion.yes': '需要',
    'no.promotion.no': '不需要',

    'fps.code.title': '轉數快(FPS) 代號：',
    'fps.beneficiary.title': '收款人：',
    'fps.qrcode.title': 'FPS 二維碼：',

    'bank.transfer.bank.name.title': '銀行名稱：',
    'bank.transfer.bank.account.title': '銀行帳號：',
    'bank.transfer.beneficiary.title': '收款人：',

    'no.file.chosen': '沒有選擇檔案',
    'custom.amount': '自訂金額 (港幣)',

    'contact_language.en': '英文',
    'contact_language.zh_hk': '中文',

    'paid.successful': '多謝捐款',

    'statement.one': '* 我們將暫時從您的信用卡帳戶扣取等同於每月捐款的金額以驗證您的帳號資訊。這些扣款並非實際付費，而所需的時間會視各銀行而定。實際捐款將於每月10號過賬。',
    'statement.two': '* 我們會於每年3月31日財政年度完結時，為每月捐款會員準備年度收據，於4月至5月發出至會員的電郵地址，如捐款屬於單次捐款，你將會在捐款後6星期內收到收據。',

    'donation.info': '捐款資料',

    'pay.now': '立即付款',
  },
  'zh-CN': {
    locale: '简体',
    'online.donation': '网上捐款',
    'online.donation.subtitle1': '香港慈善牌照编号: REF.CP/LIC/SO/19/53510',
    'online.donation.subtitle2': '获豁税免缴编号︰91/14252',
    'online.donation.link': '了解更多',
    'form.title.index': '步骤',
    'form.title.first': '选择捐款类型',
    'form.type.selection.subtitle': '请选择:',
    'donation.plan.detail.title': '计划详情',
    'form.title.second': '选择计划',
    'form.title.third': '输入个人资料',
    'form.third.amount.title': '捐款金额',
    'form.third.monthly.amount.subtitle': '每月捐款（港币）',
    'form.third.single.amount.subtitle': '单次捐款（港币）',
    'form.third.info.title': '捐款人资料',
    'form.mandatory.fields': '必须填写',
    'form.donor.no': '捐款人号码',
    'form.donor.title': '称谓',
    'form.surname': '姓氏',
    'form.given.name': '名字',
    'form.tel.no': '联络电话号码',
    'form.email.address': '电邮地址',
    'form.age.range': '年龄层',
    'form.source': '得悉网上捐款途径',
    'form.payment.method': '付款方法',
    'form.receipt.upload': '上载 转数快/银行转帐 收据',
    'form.receipt': '收据选择',
    'form.receipt.name': '收据上之姓名',
    'form.language': '通讯语言',
    'form.organization.name': '公司/机构名称',
    'form.submit.button': '下一步',

    'form.donor.no.placeholder': '（曾捐款人适用）',
    'form.textfield.limit': '{min}~{max} 字符',

    'donation.plan': '捐款计划',
    'donation.service': '计划详情',
    'donation.amount': '捐款 (港币)',

    'plan.monthly.title': '每月捐款计划',
    'plan.monthly.detail': '每月捐款提供了稳定的支持, 让博爱堂服务中心可以计划长远的发展项目- 让我们善用一分一毫帮助更多有需要的弱势社群。',
    'plan.single.title': '单次捐款',
    'plan.single.detail': '博爱堂服务中心会珍惜您每一分一毫的捐献，您的慷慨捐助，能支持本会推动社区发展工作和救援, 令弱势社群受益。',

    'plan.detail.first.title': '老人服务',
    'plan.detail.first.detail': '为有需要长者提供生活上的援助, 拓展长者福利服务',
    'plan.detail.second.title': '医疗补贴',
    'plan.detail.second.detail': '资助病患儿童、长者及有需要基层的医疗上的开支辅助',
    'plan.detail.third.title': '家庭服务',
    'plan.detail.third.detail': '给予低收入、单亲家庭在生活上的支援',

    'donation.amount.other': '自订',

    'donation.donor.type': '身份',
    'donation.donor.type.individual': '个人捐款',
    'donation.donor.type.organization': '机构／公司',

    'donor.title.mr': '先生',
    'donor.title.ms': '女士',
    'donor.title.mrs': '太太',
    'donor.title.miss': '小姐',

    'age.range.above': '{age} 以上',

    'source.internet': '互联网',
    'source.newspaper': '报纸',
    'source.radio': '电台',
    'source.tv': '电视',
    'source.direct.mailing': '直销函件',
    'source.newsletter': '本会通讯',
    'source.our.website': '本会网页',
    'source.our.email': '电邮',
    'source.our.friends': '朋友',
    'source.our.street.fundraisers': '街头筹募大使',
    'source.our.street.telemarketing.fundraisers': '电话筹募员',
    'source.our.street.others': '其他',

    'payment.method.paypal': 'PayPal',
    'payment.method.mpgs': 'MPGS',
    'payment.method.fps': '转数快(FPS)',
    'payment.method.bank.transfer': '银行转帐',

    'receipt.selection': '收据选择',
    'receipt.needed': '需要电子收据',
    'receipt.no.needed': '毋需发收据',

    'language.zh': '中文',
    'language.en': '英文',

    'file.upload.button.text': '选择档案',

    'info.statement.title': '个人资料收集声明',
    'terms.first': '我已阅读 「 {link} 」，并清楚明白其内容.未经您事先同意，本会不会使用您的个人资料作推广用途。若您按「下一步」继续捐款，即表示您同意本会「个人资料收集声明」第4段，将您的姓名及联络资料用于直接推广。若您不同意，请在以下方格加上剔号：',
    'terms.second': '我不希望收取与「博爱堂服务中心」的任何计划及活动资讯或筹募呼吁。',
    'no.promotion': '推广讯息',
    'no.promotion.yes': '需要',
    'no.promotion.no': '不需要',

    'fps.code.title': '转数快(FPS) 代号：',
    'fps.beneficiary.title': '收款人：',
    'fps.qrcode.title': 'FPS 二维码：',

    'bank.transfer.bank.name.title': '银行名称：',
    'bank.transfer.bank.account.title': '银行帐号：',
    'bank.transfer.beneficiary.title': '收款人：',

    'no.file.chosen': '没有选择档案',
    'custom.amount': '自订金额 (港币)',

    'contact_language.en': '英文',
    'contact_language.zh_hk': '中文',

    'paid.successful': '多谢捐款',

    'statement.one': '* 我们将暂时从您的信用卡帐户扣取等同于每月捐款的金额以验证您的帐号资讯。这些扣款并非实际付费，而所需的时间会视各银行而定。实际捐款将于每月10号过账。',
    'statement.two': '* 我们会于每年3月31日财政年度完结时，为每月捐款会员准备年度收据，于4月至5月發出至会员的电邮地址，如捐款属于单次捐款，你将会在捐款后6星期内收到收据。',

    'donation.info': '捐款资料',

    'pay.now': '立即付款',
  },
}

export default lang
