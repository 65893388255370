import { axios } from 'qurtty-js-sdk'
import React from 'react'
import ReactDOM from 'react-dom'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import history from './utils/history'

axios.defaults.headers.common['Anonymous-User'] = 'qurtty'

const RouterContexts = ({ children }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/:lo'>
          {children}
        </Route>
        <Redirect to='/zh-HK/form' />
      </Switch>
    </Router>
  )
}

const HookContexts = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <RouterContexts>
      <HookContexts>
        <App />
      </HookContexts>
    </RouterContexts>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
