import { Link, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import logo from '../../assets/images/logo.png'
import SwitchLocaleButton from '../SwitchLocaleButton'

const LogoHeader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <a href={process.env.REACT_APP_HOMEPAGE_URL} target='_blank' rel='noreferrer'>
          <img className={classes.logo} src={logo} />
        </a>
        <SwitchLocaleButton />
      </div>

      <div className={classes.titleBanner}>
        <Typography className={classes.title} variant='h3'>
          <FormattedMessage id='online.donation' />
        </Typography>
        <Typography variant='subtitle1'>
          <FormattedMessage id='online.donation.subtitle1' />
        </Typography>
        <Typography variant='subtitle1'>
          <FormattedMessage id='online.donation.subtitle2' />
        </Typography>
        <Link href='https://api.qurtty.com/website-portal/documents/153/download' style={{ color: '#fdda00' }} underline='always' target='_blank' >
          <FormattedMessage id='online.donation.link' />
        </Link>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px`,
  },
  logo: {
    width: 280,
    display: 'block',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  titleBanner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(240, 85, 107)',
    padding: '120px 16px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 16px',
    },
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
}))

export default LogoHeader
