import { get } from 'lodash'
import { useContext } from 'react'
import ProjectHookContext from '../contexts/ProjectHookContext'

const usePaypalGatewayConfig = () => {
  const projectHook = useContext(ProjectHookContext)
  const websiteControl = get(projectHook.project, 'website_control')
  const paymentGatewayConfig = get(websiteControl, 'payment_gateway_config')
  const paypalGatewayConfig = get(paymentGatewayConfig, 'paypal_gateway_config')

  const enabled = get(paypalGatewayConfig, 'enabled')
  const isSandboxMode = !!get(paypalGatewayConfig, 'sandbox_mode')
  const accountId = get(paypalGatewayConfig, `${isSandboxMode ? 'sandbox' : 'production'}_account_id`)
  const clientId = get(paypalGatewayConfig, `${isSandboxMode ? 'sandbox' : 'production'}_client_id`)

  return {
    isSandboxMode,
    enabled,
    accountId,
    clientId,
  }
}

export default usePaypalGatewayConfig
