import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

const BankTransferCard = ({ bankTransferBeneficiaryConfigs }) => {
  return (
    <div className={styles.card}>
      {
        bankTransferBeneficiaryConfigs.map((tmpBankTransferBeneficiaryConfig, idx) => {
          return (
            <div key={idx}>
              <div className={styles.item}>
                <span className={styles['item-name']}>
                  <FormattedMessage id='bank.transfer.bank.name.title' />
                </span>
                <span className={styles.bold}>{tmpBankTransferBeneficiaryConfig?.bank_name}</span>
              </div>
              <div className={styles.item}>
                <span className={styles['item-name']}>
                  <FormattedMessage id='bank.transfer.bank.account.title' />
                </span>
                <span className={styles.bold}>{tmpBankTransferBeneficiaryConfig?.account_number}</span>
              </div>
              <div className={styles.item}>
                <span className={styles['item-name']}>
                  <FormattedMessage id='bank.transfer.beneficiary.title' />
                </span>
                <span className={styles.bold}>{tmpBankTransferBeneficiaryConfig?.payee_name}</span>
              </div>
              {bankTransferBeneficiaryConfigs.length - 1 !== idx && <div className={styles.break} />}
            </div>
          )
        })
      }
    </div>
  )
}

export default BankTransferCard
