import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import RequestFormHookContext from '../../contexts/RequestFormHookContext'
import useDonationFormFields from '../../hooks/useDonationFormFields'
import BankTransferCard from '../DonateForm/components/BankTranferCard'
import PaidSuccessful from '../PaidSuccessful'

const OneTimeDonationBankTransfer = () => {
  const { formatMessage } = useIntl()
  const donationFormFieldsHook = useDonationFormFields()
  const requestFormHook = useContext(RequestFormHookContext)
  const donationFormData = donationFormFieldsHook.getFormData()
  const isBankTransfer = donationFormData.paymentMethod === formatMessage({ id: 'payment.method.bank.transfer' })

  if (!isBankTransfer) {
    return <div />
  }

  return (
    <>
      <PaidSuccessful />

      <BankTransferCard bankTransferBeneficiaryConfigs={[requestFormHook.currentRequest.getOrder().bank_transfer_payment_datum.bank_transfer_beneficiary_config]} />

    </>
  )
}

export default OneTimeDonationBankTransfer
