import { LinearProgress } from '@material-ui/core'
import { useContext, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import LogoHeader from './components/LogoHeader'
import HostedProductsHookContext from './contexts/HostedProductsHookContext'
import ProjectHookContext from './contexts/ProjectHookContext'
import RequestFormHookContext from './contexts/RequestFormHookContext'
import RequestGroupsHookContext from './contexts/RequestGroupsHookContext'
import useHostedProducts from './hooks/useHostedProducts'
import useRequestForm from './hooks/useRequestForm'
import useRequestGroups from './hooks/useRequestGroups'
import ConfirmPage from './pages/ConfirmPage'
import FormPage from './pages/FormPage'

const Main = () => {
  const projectHook = useContext(ProjectHookContext)
  const requestGroupsHook = useRequestGroups()
  const hostedProductsHook = useHostedProducts()
  const requestFormHook = useRequestForm({ requestGroup: requestGroupsHook.currentRequestGroup })

  const { path } = useRouteMatch()

  useEffect(() => {
    if (projectHook.fetchProojectState.isDone) {
      requestGroupsHook.fetchRequestGroups()
      hostedProductsHook.fetchHostedProducts()
    }
  }, [projectHook.fetchProojectState.isDone])

  if (!projectHook.fetchProojectState.isDone || !hostedProductsHook.fetchHostedProductsState.isDone) {
    return (
      <LinearProgress />
    )
  }

  const HookContexts = ({ children }) => {
    return (
      <RequestGroupsHookContext.Provider value={requestGroupsHook}>
        <HostedProductsHookContext.Provider value={hostedProductsHook}>
          <RequestFormHookContext.Provider value={requestFormHook}>
            {children}
          </RequestFormHookContext.Provider>
        </HostedProductsHookContext.Provider>
      </RequestGroupsHookContext.Provider>
    )
  }

  return (
    <HookContexts>
      <LogoHeader />

      <Switch>
        <Route path={`${path}/form`} component={FormPage} />
        <Route path={`${path}/confirm`} component={ConfirmPage} />
      </Switch>
    </HookContexts>
  )
}

export default Main
