import { useState } from 'react'

export interface IUseFetchingStateHook {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  isDone: boolean
  errorMsg: any
  markLoading: () => void
  markSuccess: () => void
  markError: (e) => void
}

const useFetchingState = () => {
  const [fetchingState, setFetchingState] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMsg: null,
  })

  const isDone = fetchingState.isSuccess || fetchingState.isError

  const markLoading = () => {
    setFetchingState({
      ...fetchingState,
      isLoading: true,
      isSuccess: false,
      isError: false,
    })
  }

  const markSuccess = () => {
    setFetchingState({
      ...fetchingState,
      isLoading: false,
      isSuccess: true,
      isError: false,
    })
  }

  const markError = (e) => {
    setFetchingState({
      ...fetchingState,
      isLoading: false,
      isSuccess: false,
      isError: true,
      errorMsg: e,
    })
  }

  return {
    isLoading: fetchingState.isLoading,
    isError: fetchingState.isError,
    isSuccess: fetchingState.isSuccess,
    errorMsg: fetchingState.errorMsg,
    isDone,
    markLoading,
    markSuccess,
    markError,
  }
}

export default useFetchingState
