import { FormControl, FormControlLabel, Grid, RadioGroup } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { get, snakeCase } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import DonationHookContext from '../../../../contexts/DonationHookContext'
import useIsoLo from '../../../../hooks/useIsoLo'
import WhiteRadio from '../../../WhiteRadio'
import styles from './style.module.scss'

const DonationTypeSelectionSection = () => {
  const isoLo = useIsoLo()
  const lo = snakeCase(isoLo)
  const donationHook = useContext(DonationHookContext)

  const { values, setFieldValue } = useFormikContext()
  const donationType = get(values, 'donation_type')

  useEffect(() => {
    if (donationType === 'Monthly Donation') {
      setFieldValue('donation_amount', '$195')
    } else {
      setFieldValue('donation_amount', '$295')
    }
    setFieldValue('donation_service', 'Elderly Services')
  }, [donationType])

  return (
    <div className={styles['plan-selection-section']}>
      <FormControl>
        <div className={styles.sub}>
          <FormattedMessage id='form.type.selection.subtitle' />
        </div>

        <Field name='donation_type'>
          {({ field: { onChange, name, value } }) => (
            <RadioGroup
              aria-label='donate plan'
              className={styles['plan-group']}
              name={name}
              value={value}
              onChange={onChange}
            >
              <Grid container spacing={2}>
                {
                  donationHook.donationTypes.map((doantionProduct, idx) => (
                    <Grid item key={idx} xs={12} md={6}>
                      <FormControlLabel
                        value={doantionProduct.name_en}
                        control={<WhiteRadio />}
                        label={doantionProduct[`name_${lo}`]}
                        className={styles['plan-block'] + ' ' + (value === doantionProduct.name_en ? styles['plan-block-selected'] : '') }
                        data-detail={get(doantionProduct, `description_${lo}[0].content`)}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </RadioGroup>
          )}
        </Field>

      </FormControl>
    </div>
  )
}

export default DonationTypeSelectionSection
