import { FormControl, FormControlLabel, Grid, RadioGroup } from '@material-ui/core'
import { Field } from 'formik'
import { get, snakeCase } from 'lodash'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import DonationHookContext from '../../../../contexts/DonationHookContext'
import useIsoLo from '../../../../hooks/useIsoLo'
import WhiteRadio from '../../../WhiteRadio'
import StepTitle from '../StepTitle'
import styles from './style.module.scss'

const StepTwo = () => {
  const { formatMessage } = useIntl()
  const isoLo = useIsoLo()
  const lo = snakeCase(isoLo)

  const donationHook = useContext(DonationHookContext)

  return (
    <div className={styles['section-two']}>
      <StepTitle
        index={2}
        title={formatMessage({ id: 'form.title.second' })}
      />

      <div className={styles['plan-selection-section']}>
        <FormControl>
          <div className={styles.sub}>
            <FormattedMessage id='donation.plan.detail.title' />
          </div>

          <Field name='donation_service'>
            {({ field: { onChange, name, value } }) => (
              <RadioGroup
                aria-label='donate plan'
                className={styles['plan-group']}
                name={name}
                value={value}
                onChange={onChange}
              >
                <Grid container spacing={2}>
                  {
                    donationHook.donationServices.map((service, idx) => {
                      const photos = service.getPhotos()
                      return (
                        <Grid item key={idx} xs={12} md={6} >
                          <div className={styles['service-block'] + ' ' + (value === service.name_en ? styles['service-block-selected'] : '') }>
                            <div className={styles['block-content']}>
                              {
                                <FormControlLabel
                                  value={service.name_en}
                                  control={<WhiteRadio />}
                                  label={service[`name_${lo}`]}
                                  data-detail={get(service, `description_${lo}[0].content`)}
                                />
                              }
                            </div>
                            { photos[0] && <div className={styles['block-image']} style={{ backgroundImage: `url(${photos[0].url})` }} /> }
                          </div>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </RadioGroup>
            )}

          </Field>

        </FormControl>
      </div>

    </div>
  )
}

export default StepTwo
