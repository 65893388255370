import { get } from 'lodash'
import { useContext } from 'react'
import ProjectHookContext from '../contexts/ProjectHookContext'

const useMpgsGatewayConfig = () => {
  const projectHook = useContext(ProjectHookContext)
  const websiteControl = get(projectHook.project, 'website_control')
  const paymentGatewayConfig = get(websiteControl, 'payment_gateway_config')
  const mpgsGatewayConfig = get(paymentGatewayConfig, 'mpgs_gateway_config')

  const enabled = get(mpgsGatewayConfig, 'enabled')
  const isSandboxMode = !!get(mpgsGatewayConfig, 'sandbox_mode')
  const merchantId = get(mpgsGatewayConfig, `${isSandboxMode ? 'sandbox' : 'production'}_merchant_id`)
  const password = get(mpgsGatewayConfig, `${isSandboxMode ? 'sandbox' : 'production'}_password`)

  return {
    enabled,
    merchantId,
    password,
  }
}

export default useMpgsGatewayConfig
