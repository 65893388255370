import parsePhoneNumber from 'libphonenumber-js'
import { get, pick, snakeCase } from 'lodash'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import lang from '../constants/lang'
import RequestGroupsHookContext from '../contexts/RequestGroupsHookContext'
import useDonation from './useDonation'
import useDonationFormFields from './useDonationFormFields'
import useIsoLo from './useIsoLo'

const useFormRequestConverter = (formData) => {
  const isoLo = useIsoLo()
  const { formatMessage } = useIntl()
  const donationFormFieldsHook = useDonationFormFields('zh-HK')
  const donationHook = useDonation(formData)
  const requestGroupsHook = useContext(RequestGroupsHookContext)
  const extraFormProperties = get(requestGroupsHook.currentRequestGroup, 'extra_form_schema.properties') || {}
  const extraFormSchemaQuestions = Object.keys(extraFormProperties).map((propertyId) => pick(extraFormProperties[propertyId], ['title', 'uuid', 'displayOrder']))
  const findCustomFieldIdByName = (name) => Object.keys(extraFormProperties || {}).find((propertyId) => get(extraFormProperties[propertyId], `title.${snakeCase(isoLo)}`) === name)

  // 'form.receipt.name'
  const ageRangeFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.age.range' }))
  const fromSourceFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.source' }))
  const paymentMethodFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.payment.method' }))
  const receiptNeededFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.receipt' }))
  const receiptNameFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.receipt.name' }))
  const receiptUploadFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.receipt.upload' }))
  const languageFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.language' }))
  const noPromotionFieldId = findCustomFieldIdByName(formatMessage({ id: 'no.promotion' }))
  const donorNoFieldId = findCustomFieldIdByName(formatMessage({ id: 'form.donor.no' }))

  const convertToRequestAttributes = (data) => {
    const {
      tel,
      email,
      title,
      firstName,
      lastName,
      donorType,
      donationType,
      donationAmount,
      donationService,
      paymentMethod,
      organizationName,
      ageRange,
      fromSource,
      receiptNeeded,
      receiptName,
      donorNo,
      receiptFile,
      contactLanguage,
      noPromotion,
      customAmount,
    } = donationFormFieldsHook.getFormData(data)

    const isMonthly = donationType === 'Monthly Donation'
    const selectedDonationType = isMonthly ? donationHook.monthlyDonation : donationHook.oneTimeDonation
    const selectedDonationService = (isMonthly ? donationHook.monthlyDonationServiceProducts : donationHook.oneTimeDonationServiceProducts).find((service) => service.name_en === donationService)
    const selectedDonationServiceGrouping = (isMonthly ? donationHook.monthlyDonationServiceGroupings : donationHook.oneTimeDonationServicesGroupings).find((grouping) => grouping.product_id === selectedDonationService.id)
    const selectedDonationAmount = (isMonthly ? donationHook.monthlyDonationAmountProducts : donationHook.oneTimeDonationAmountProducts).find((amount) => amount.name_en === donationAmount)
    const selectedDonationAmountGrouping = (isMonthly ? donationHook.monthlyDonationAmountGroupings : donationHook.oneTimeDonationAmountGroupings).find((grouping) => grouping.product_id === selectedDonationAmount.id)

    const parsedPhoneNumber = parsePhoneNumber(tel)
    const billingPlan = selectedDonationAmount.getBillingPlans()[0]

    const isCustomAmount = !String(donationAmount || '').startsWith('$')

    const paymentMethodTypes = {
      0: 'paypal',
      1: 'fps',
      2: 'bank_transfer',
      3: 'mpgs',
    }

    const donorAttributes = donorType === 'individual' ? {
      member_card_attributes: {
        [`firstname_${snakeCase(isoLo)}`]: firstName,
        [`lastname_${snakeCase(isoLo)}`]: lastName,
        [`title_${snakeCase(isoLo)}`]: title,
        emails_attributes: email ? [{ content: email }] : [],
        phones_attributes: tel ? [{ country_code: parsedPhoneNumber?.countryCallingCode || '852', number: parsedPhoneNumber?.nationalNumber || tel }] : [],
      },
    } : {
      member_card_attributes: {},
      company_card_attributes: {
        [`name_${snakeCase(isoLo)}`]: organizationName,
        phones_attributes: tel ? [{ country_code: parsedPhoneNumber?.countryCallingCode || '852', number: parsedPhoneNumber?.nationalNumber || tel }] : [],
        emails_attributes: email ? [{ content: email }] : [],
      },
    }

    const particlePurchasesAttributes = [
      {
        particle_type: 'V3::Product',
        particle_id: get(selectedDonationType, 'id'),
        quantity: 1,
        child_particle_purchases_attributes: [
          {
            particle_type: 'V3::Product',
            particle_id: selectedDonationService.id,
            particle_group_type: 'V3::SubproductGroup',
            particle_group_id: selectedDonationServiceGrouping.subproduct_group_id,
            quantity: 1,
          },
          {
            particle_type: 'V3::Product',
            particle_id: selectedDonationAmount.id,
            particle_group_type: 'V3::SubproductGroup',
            particle_group_id: selectedDonationAmountGrouping.subproduct_group_id,
            quantity: 1,
          },
        ],
      },
    ]

    const orderAttributes = {
      price: isCustomAmount ? customAmount : get(selectedDonationAmount, 'price'),
      currency: get(selectedDonationAmount, 'currency'),
      gateway_provider: paymentMethodTypes[get(data, 'payment_method') || 0],
    }

    const donationOrderParams = isMonthly ? {
      subscription_intents_attributes: [{ billing_plan_id: get(billingPlan, 'id') }],
    } : {
      order_attributes: orderAttributes,
    }

    const extraFormData = {
      [ageRangeFieldId as any]: ageRange,
      [fromSourceFieldId as any]: fromSource,
      [paymentMethodFieldId as any]: paymentMethod,
      [receiptNeededFieldId as any]: receiptNeeded,
      [receiptNameFieldId as any]: receiptName,
      [receiptUploadFieldId as any]: receiptFile,
      [languageFieldId as any]: contactLanguage,
      [noPromotionFieldId as any]: noPromotion,
      [donorNoFieldId as any]: donorNo,
      questions: extraFormSchemaQuestions,
    }

    const personalFields = [
      {
        title: lang['zh-HK']['form.donor.title'],
        value: lang['zh-HK'][`donor.title.${title}`],
      },
      {
        title: lang['zh-HK']['form.surname'],
        value: firstName,
      },
      {
        title: lang['zh-HK']['form.given.name'],
        value: lastName,
      },
    ]

    const organizationFields = [
      {
        title: lang['zh-HK']['form.organization.name'],
        value: organizationName,
      },
    ]

    const formPreviewData = [
      {
        name: lang['zh-HK']['donation.info'],
        fields: [
          {
            title: lang['zh-HK']['form.donor.no'],
            value: donorNo || '-',
          },
          {
            title: lang['zh-HK']['donation.plan'],
            value: selectedDonationType.name_zh_hk,
          },
          {
            title: lang['zh-HK']['donation.service'],
            value: selectedDonationService.name_zh_hk,
          },
          {
            title: lang['zh-HK']['donation.amount'],
            value: selectedDonationAmount.name_zh_hk,
          },
          {
            title: lang['zh-HK']['donation.donor.type'],
            value: lang['zh-HK'][`donation.donor.type.${donorType}`],
          },
          ...(donorType === 'individual' ? personalFields : organizationFields),
          {
            title: lang['zh-HK']['form.tel.no'],
            value: tel,
          },
          {
            title: lang['zh-HK']['form.email.address'],
            value: email,
          },
          {
            title: lang['zh-HK']['form.age.range'],
            value: ageRange,
          },
          {
            title: lang['zh-HK']['form.source'],
            value: fromSource,
          },
          {
            title: lang['zh-HK']['form.payment.method'],
            value: paymentMethod,
          },
          {
            title: lang['zh-HK']['receipt.selection'],
            value: receiptNeeded,
          },
        ],
      },
    ]

    return {
      ...donorAttributes,
      ...donationOrderParams,
      user_locale: snakeCase(isoLo),
      extra_form_data: extraFormData,
      form_preview_data: formPreviewData,
      particle_purchases_attributes: particlePurchasesAttributes,
    }
  }

  return {
    convertToRequestAttributes,
  }
}

export default useFormRequestConverter
