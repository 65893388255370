import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { map } from 'lodash'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import DonationHookContext from '../../contexts/DonationHookContext'
import useDonationFormFields from '../../hooks/useDonationFormFields'

const DonationConfirmList = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const donationFormFieldsHook = useDonationFormFields()
  const donationFormData = donationFormFieldsHook.getFormData()
  const donationHook = useContext(DonationHookContext)

  const personalFields = [
    {
      title: formatMessage({ id: 'form.donor.title' }),
      value: formatMessage({ id: `donor.title.${donationFormData.title}` }),
    },
    {
      title: formatMessage({ id: 'form.surname' }),
      value: donationFormData.firstName,
    },
    {
      title: formatMessage({ id: 'form.given.name' }),
      value: donationFormData.lastName,
    },
  ]

  const organizationFields = [
    {
      title: formatMessage({ id: 'form.organization.name' }),
      value: donationFormData.organizationName,
    },
  ]

  const formConfirmFields = [
    {
      title: formatMessage({ id: 'donation.plan' }),
      value: formatMessage({ id: `plan.${donationHook.isMonthlyDonation ? 'monthly' : 'single'}.title` }),
    },
    {
      title: formatMessage({ id: 'donation.service' }),
      value: donationHook.donationServices.find((service) => service.name_en === donationFormData.donationService).name_zh_hk,
    },
    {
      title: formatMessage({ id: 'donation.amount' }),
      value: donationHook.donationAmounts.find((amount) => amount.name_en === donationFormData.donationAmount).name_zh_hk,
    },
    {
      title: formatMessage({ id: 'donation.donor.type' }),
      value: formatMessage({ id: `donation.donor.type.${donationFormData.donorType}` }),
    },
    {
      title: formatMessage({ id: 'form.donor.no' }),
      value: donationFormData.donorNo || '-',
    },
    ...(donationFormData.donorType === 'individual' ? personalFields : organizationFields),
    {
      title: formatMessage({ id: 'form.tel.no' }),
      value: donationFormData.tel,
    },
    {
      title: formatMessage({ id: 'form.email.address' }),
      value: donationFormData.email,
    },
    {
      title: formatMessage({ id: 'form.age.range' }),
      value: donationFormData.ageRange,
    },
    {
      title: formatMessage({ id: 'form.source' }),
      value: donationFormData.fromSource,
    },
    {
      title: formatMessage({ id: 'form.payment.method' }),
      value: donationFormData.paymentMethod,
    },
    {
      title: formatMessage({ id: 'receipt.selection' }),
      value: donationFormData.receiptNeeded,
    },
  ]

  return (
    <Table className={classes.root}>
      <TableBody>
        {map(formConfirmFields, ({ title, value }, key) => (
          <TableRow key={key}>
            <TableCell>
              {title}
            </TableCell>
            <TableCell align='right'>
              {value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export default DonationConfirmList
