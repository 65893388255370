import { find, get, snakeCase } from 'lodash'
import { useContext } from 'react'
import AGES_TYPES from '../constants/AGES_TYPES'
import PAYMENT_METHOD_TYPES from '../constants/PAYMENT_METHOD_TYPES'
import WAYS_TO_KNOW_DONATE_ONLINE from '../constants/WAYS_TO_KNOW_DONATE_ONLINE'
import lang from '../constants/lang'
import RequestFormHookContext from '../contexts/RequestFormHookContext'
import useIsoLo from './useIsoLo'

const useDonationFormFields = (locale?: any) => {
  const isoLo = useIsoLo()
  locale = locale || isoLo
  const requestFormHook = useContext(RequestFormHookContext)

  const ageTypes = AGES_TYPES(locale)
  const paymentMethodTypes = PAYMENT_METHOD_TYPES(locale)
  const waysToKnowDonateOnline = WAYS_TO_KNOW_DONATE_ONLINE(locale)

  const getFormData = (data = requestFormHook.formValues) => {
    const donationType = get(data, 'donation_type')
    const donationAmount = get(data, 'donation_amount')
    const donationService = get(data, 'donation_service')
    const donorType = get(data, 'donor_type')
    const donorNo = get(data, 'donor_no')
    const title = get(data, 'title')
    const firstName = get(data, 'firstname')
    const lastName = get(data, 'lastname')
    const organizationName = get(data, 'organization_name')
    const noPromotion = lang[locale][`no.promotion.${get(data, 'no_promotion') ? 'no' : 'yes'}`]
    const tel = get(data, 'tel')
    const email = get(data, 'email')
    const receiptName = get(data, 'receipt_name')
    const receiptFile = get(data, 'receipt_file')
    const contactLanguage = lang[locale][`contact_language.${snakeCase(get(data, 'contact_language') || 'en')}`]
    const ageRange = get(find(ageTypes, ({ value }) => value === get(data, 'age_range')), 'label')
    const fromSource = get(find(waysToKnowDonateOnline, ({ value }) => value === get(data, 'from_source')), 'label')
    const paymentMethod = get(paymentMethodTypes, get(data, 'payment_method'))
    const receiptNeeded = lang[locale][get(data, 'receipt_needed') ? 'receipt.needed' : 'receipt.no.needed']
    const customAmount = get(data, 'custom_amount')

    return {
      customAmount,
      contactLanguage,
      receiptName,
      donationType,
      donationService,
      donationAmount,
      donorType,
      donorNo,
      title,
      firstName,
      lastName,
      organizationName,
      noPromotion,
      tel,
      email,
      ageRange,
      fromSource,
      paymentMethod,
      receiptNeeded,
      receiptFile,
    }
  }

  return {
    getFormData,
  }
}

export default useDonationFormFields
