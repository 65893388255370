import lang from './lang'

export default (locale) => {
  return [
    {
      value: 0,
      label: lang[locale]['source.internet'],
    },
    {
      value: 1,
      label: lang[locale]['source.newspaper'],
    },
    {
      value: 2,
      label: lang[locale]['source.radio'],
    },
    {
      value: 3,
      label: lang[locale]['source.tv'],
    },
    {
      value: 4,
      label: lang[locale]['source.direct.mailing'],
    },
    {
      value: 5,
      label: lang[locale]['source.newsletter'],
    },
    {
      value: 6,
      label: lang[locale]['source.our.website'],
    },
    {
      value: 7,
      label: lang[locale]['source.our.email'],
    },
    {
      value: 8,
      label: lang[locale]['source.our.friends'],
    },
    {
      value: 9,
      label: lang[locale]['source.our.street.fundraisers'],
    },
    {
      value: 10,
      label: lang[locale]['source.our.street.telemarketing.fundraisers'],
    },
    {
      value: 11,
      label: lang[locale]['source.our.street.others'],
    },
  ]
}
