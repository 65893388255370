import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './style.module.scss'

const PersonalForm = () => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.form}>

      <FormControl component='fieldset'>
        <div className={styles['radio-group-label']}>
          <FormattedMessage id='form.donor.title' />
        </div>

        <Field name='title'>
          {({ field: { onChange, name, value } }) => (
            <RadioGroup
              row
              name={name}
              value={value}
              aria-label={name}
              onChange={onChange}
            >
              <FormControlLabel
                value='mr'
                control={<Radio />}
                label={formatMessage({ id: 'donor.title.mr' })}
              />
              <FormControlLabel
                value='ms'
                control={<Radio />}
                label={formatMessage({ id: 'donor.title.ms' })}
              />
              <FormControlLabel
                value='mrs'
                control={<Radio />}
                label={formatMessage({ id: 'donor.title.mrs' })}
              />
              <FormControlLabel
                value='miss'
                control={<Radio />}
                label={formatMessage({ id: 'donor.title.miss' })}
              />
            </RadioGroup>
          )}
        </Field>

      </FormControl>

      <Field name='firstname'>
        {({ field: { onChange, name, value } }) => (
          <TextField
            required
            name={name}
            margin='dense'
            className={styles['text-input']}
            onChange={onChange}
            value={value || ''}
            label={formatMessage({ id: 'form.surname' })}
            placeholder={formatMessage({ id: 'form.textfield.limit' }, { min: 2, max: 30 })}
          />
        )}
      </Field>

      <Field name='lastname'>
        {({ field: { onChange, name, value } }) => (
          <TextField
            required
            name={name}
            margin='dense'
            className={styles['text-input']}
            onChange={onChange}
            value={value || ''}
            label={formatMessage({ id: 'form.given.name' })}
            placeholder={formatMessage({ id: 'form.textfield.limit' }, { min: 2, max: 30 })}
          />
        )}
      </Field>

    </div>
  )
}

export default PersonalForm
