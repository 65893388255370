import { get } from 'lodash'
import {
  fk,
  oneToOne,
} from 'redux-orm'
import { polymorphicAssoiciations } from '../../helpers/polymorphicHelper'
import { session } from '../schemas'
import { QurttyModel } from './modelDefault'
import { V3_ProductSegment } from './productModel'

const ATTR_TYPE_NAMES = ['string', 'text', 'number', 'date', 'time', 'html', 'json', 'V3::MemberCard', 'V3::CompanyCard', 'V3::Product', 'V3::Blog', 'V3::Event']

export class V3_Project extends QurttyModel {
  static modelName = 'V3_Project'
  static fields = {
    cover_id: fk({ to: 'V3_Photo', as: 'cover', relatedName: 'covered_projects' }),
  }

  getOwner () { return this.getPolymorphicHolder('owner') }

  getStructures () {
    return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'hosted' }).toModelArray()
  }

  getPhotoExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'photo_extension' }).at(0) }
  getMemberCardExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'member_card_extension' }).at(0) }
  getCompanyCardExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'company_card_extension' }).at(0) }
  getProductExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'product_extension' }).at(0) }
  getBlogExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'blog_extension' }).at(0) }
  getEventExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'event_extension' }).at(0) }

  getHostedBlogs () {
    return polymorphicAssoiciations(this, 'V3_Blog', 'V3::Project', 'host')
  }

  getHostedMemberCards () {
    return polymorphicAssoiciations(this, 'V3_MemberCard', 'V3::Project', 'host')
  }

  getHostedCompanyCards () {
    return polymorphicAssoiciations(this, 'V3_CompanyCard', 'V3::Project', 'host')
  }

  getHostedEvents () {
    return polymorphicAssoiciations(this, 'V3_Event', 'V3::Project', 'host')
  }

  getHostedPhotos () {
    return polymorphicAssoiciations(this, 'V3_Photo', 'V3::Project', 'host')
  }

  getHostedProducts () {
    return polymorphicAssoiciations(this, 'V3_Product', 'V3::Project', 'host')
  }
}
export class V3_ProjectCheckpoint extends QurttyModel {
  static modelName = 'V3_ProjectCheckpoint'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'project_checkpoints' }),
  }
}
export class V3_Doc extends QurttyModel {
  static modelName = 'V3_Doc'
  static fields = {
    cover_id: fk({ to: 'V3_Photo', as: 'cover', relatedName: 'covered_docs' }),
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'docs' }),
  }

  getStructure () {
    return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'hosted' }).at(0)
  }

  getPhotoExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'photo_extension' }).at(0) }
  getMemberCardExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'member_card_extension' }).at(0) }
  getCompanyCardExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'company_card_extension' }).at(0) }
  getProductExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'product_extension' }).at(0) }
  getBlogExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'blog_extension' }).at(0) }
  getEventExtensionStructure () { return this.getPolymorphicMany('V3_Eav_Structure', 'host').filter({ category: 'event_extension' }).at(0) }
}
export class V3_WebsiteControl extends QurttyModel {
  static modelName = 'V3_WebsiteControl'
  static fields = {
    project_id: oneToOne({ to: 'V3_Project', as: 'project', relatedName: 'website_control' }),
    favicon_id: fk({ to: 'V3_Photo', as: 'favicon', relatedName: 'faviconed_projects' }),
    header_logo_id: fk({ to: 'V3_Photo', as: 'header_logo', relatedName: 'header_logoed_projects' }),
    footer_logo_id: fk({ to: 'V3_Photo', as: 'footer_logo', relatedName: 'footer_logoed_projects' }),
    background_image_id: fk({ to: 'V3_Photo', as: 'background_image', relatedName: 'background_imaged_projects' }),
  }

  static INCLUDES_PARAMS = {
    product_catalog_style: {},
    photo_catalog_style: {},
    card_catalog_style: {},
    blog_catalog_style: {},
    event_catalog_style: {},
    header_wave_style: {},
    footer_wave_style: {},
  }

  project: any
  website_domains: any
  _wave_styles: any

  getWebsiteUrl ({ useId } = { useId: false }) {
    const project = this.project
    const memberSlug = project.member_slug
    const v5CompanySlug = project.v5_company_slug
    let url
    const verifiedWebsiteDomain = this.website_domains.toModelArray().find((dom) => dom.verified)
    if (verifiedWebsiteDomain) {
      url = 'https://' + verifiedWebsiteDomain.content
    } else {
      url = process.env.REACT_APP_SITE_URL_PREFIX
      url += memberSlug ? `/m/${memberSlug}` : `/c/${v5CompanySlug}`
      const projectSlug = useId ? project.id : project.slug
      url += `/q-project/${projectSlug}`
    }
    return url
  }

  getHeaderWaveStyle () {
    return this._wave_styles.toModelArray().find((wavStyl) => wavStyl.category === 'web_header')
  }

  getFooterWaveStyle () {
    return this._wave_styles.toModelArray().find((wavStyl) => wavStyl.category === 'web_footer')
  }

  getSeoSetting () {
    return this.getPolymorphicOne('V3::SeoSetting', 'holder')
  }
}
export class V3_WebsiteNavLink extends QurttyModel {
  static modelName = 'V3_WebsiteNavLink'
  static fields = {
    website_control_id: fk({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: 'website_nav_links' }),
    custom_icon_id: fk({ to: 'V3_Photo', as: 'custom_icon', relatedName: 'custom_iconed_website_nav_links' }),
  }
}
export class V3_WebsiteDomain extends QurttyModel {
  static modelName = 'V3_WebsiteDomain'
  static fields = {
    website_control_id: fk({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: 'website_domains' }),
  }
}

export class V3_ExternalLink extends QurttyModel {
  static modelName = 'V3_ExternalLink'
  static fields = {
    website_control_id: fk({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: 'external_links' }),
  }
}

export class V3_WebsiteNavGroup extends QurttyModel {
  static modelName = 'V3_WebsiteNavGroup'
  static fields = {
    website_control_id: fk({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: 'website_nav_groups' }),
  }
}

export class V3_WaveStyle extends QurttyModel {
  modelUrl = 'v3/wave_styles'
  static modelName = 'V3_WaveStyle'
  static fields = {
    website_control_id: fk({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: '_wave_styles' }),
  }
}

export class V3_Webpage extends QurttyModel {
  static modelName = 'V3_Webpage'
  static fields = {
    website_control_id: fk({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: 'webpages' }),
    background_image_id: fk({ to: 'V3_Photo', as: 'background_image', relatedName: 'background_imaged_webpages' }),
  }

  getSeoSetting () {
    return this.getPolymorphicOne('V3::SeoSetting', 'holder')
  }
}

export class V3_SeoSetting extends QurttyModel {
  static modelName = 'V3_SeoSetting'
  static fields = {
    og_image_id: fk({ to: 'V3_Photo', as: 'og_image', relatedName: 'seo_settings' }),
  }
}

export class V3_SeoKeyword extends QurttyModel {
  static modelName = 'V3_SeoKeyword'
  static fields = {
    seo_setting_id: fk({ to: 'V3_SeoSetting', as: 'seo_setting', relatedName: 'seo_keywords' }),
  }
}

export class V3_WebpageSection extends QurttyModel {
  static modelName = 'V3_WebpageSection'
  static fields = {
    webpage_id: fk({ to: 'V3_Webpage', as: 'webpage', relatedName: 'webpage_sections' }),
    structure_id: fk({ to: 'V3_Eav_Structure', as: 'structure', relatedName: 'webpage_sections' }),
    request_group_id: fk({ to: 'V3_RequestGroup', as: 'request_group', relatedName: 'webpage_sections' }),
    website_nav_group_id: fk({ to: 'V3_WebsiteNavGroup', as: 'website_nav_group', relatedName: 'webpage_sections' }),
    background_image_id: fk({ to: 'V3_Photo', as: 'background_image', relatedName: 'background_imaged_webpage_sections' }),
  }

  static INCLUDES_PARAMS = {
    background_image: {},
    webpage_banner_section_style: {},
    webpage_hot_products_section_style: {},
    webpage_products_section_style: {
      section_particle_item_extension_attr_exposures: {},
    },
    webpage_event_album_section_style: {},
    webpage_contact_us_section_style: {},
    webpage_web_album_section_style: {},
    webpage_inscription_section_style: {},
    webpage_q_and_a_section_style: {},
    webpage_about_us_section_style: {},
    webpage_slides_section_style: {},
    webpage_blogs_section_style: {},
    webpage_events_section_style: {},
    webpage_committees_section_style: {},
    webpage_featured_section_style: {
      webpage_featured_item_button_style: {},
      webpage_featured_item_title_button_style: {},
      webpage_featured_item_subtitle_button_style: {},
      webpage_featured_item_description_button_style: {},
      webpage_featured_item_date_button_style: {},
      webpage_featured_item_url_button_style: {},
    },
    webpage_quiz_game_section_style: {
      game_logo: {},
      organization_logo: {},
      answer_pending_icon: {},
      answer_selected_icon: {},
      answer_correct_icon: {},
      answer_incorrect_icon: {},
      quizzer_avatar: {},
      quizzer_avatar_selection_icon: {},
      quit_icon: {},
      expired_icon: {},
      timeout_icon: {},
      ranking_icon: {},
      ongoing_score_icon: {},
      final_score_icon: {},
      ranking_header_photo: {},
    },
    product_segments: V3_ProductSegment.INCLUDES_PARAMS,
  }

  getProductSegments () {
    // return filter(session.V3_ProductSegment, val => val.bloggable_type === 'V3::Product' && val.bloggable_id === this.id).toModelArray()
    return polymorphicAssoiciations(this, 'V3_ProductSegment', 'V3::WebpageSection', 'bloggable')
  }

  getPath () {
    return `/${(this as any).webpage.url_path}/${(this as any).url_path}`
  }

  getLinkedAttrs () {
    return this.getAssociation('webpage_section_attrs').map((attrPointer) => attrPointer.attr)
  }

  getWebSectionStyle () {
    return this[(this as any)._section_style_key] || {}
  }
}
export class V3_WebpageSectionAttr extends QurttyModel {
  static modelName = 'V3_WebpageSectionAttr'
  static fields = {
    webpage_section_id: fk({ to: 'V3_WebpageSection', as: 'webpage_section', relatedName: 'webpage_section_attrs' }),
    attr_id: fk({ to: 'V3_Eav_Attr', as: 'attr', relatedName: 'webpage_section_attrs' }),
  }
}

export class V3_Eav_AttrType extends QurttyModel {
  static modelName = 'V3_Eav_AttrType'
  static fields = {}
}
export class V3_Eav_ParticleType extends QurttyModel {
  static modelName = 'V3_Eav_ParticleType'
  static fields = {}
}

// Eav_Structure
export class V3_Eav_Structure extends QurttyModel {
  static modelName = 'V3_Eav_Structure'
  static fields = {}
  host_id: any
  host_type: any
  attrs: any

  static INCLUDES_PARAMS = { attrs: {}, sections: { section_attrs: {} } }

  host () {
    return session[this.host_type.replace(/::/g, '_')].withId(this.host_id)
  }

  entitiesOfParent (parentEntityId, roomAttrId) {
    return this.getAss('entities').filter((ett) => {
      const parentValues = session.V3_Eav_Value.filter((val) => val.content_particle_type === 'V3::Eav::Entity' && val.content_particle_id === ett.id).toModelArray()
      return parentValues.some((val) => val.entity_id === parentEntityId && val.attr_id === roomAttrId)
    })
  }

  getAttr (key) {
    return this.attrs.toModelArray().find((attr) => attr.key === key)
  }
}

export class V3_Eav_Entity extends QurttyModel {
  static modelName = 'V3_Eav_Entity'
  static fields = {
    structure_id: fk({ to: 'V3_Eav_Structure', as: 'structure', relatedName: 'entities' }),
    entity_website_style: oneToOne('V3_Eav_EntityWebsiteStyle'),
  }

  static INCLUDES_PARAMS = {
    values: { content_particle: {}, file_attachment: { blob: {} } },
  }

  structure: any
  structure_id: any
  values: any

  /**
   * get an attr by index/key
   * @param  {Number/String/QurttyModel} arg attr index/key of the attr, or attr itself
   * @return {QurttyModel}     the attr
   */
  parseAttr (arg) {
    let attr
    if (typeof arg === 'object') {
      attr = arg
    } else {
      let structure = this.structure
      if (!structure) {
        structure = session.V3_Eav_Structure.withId(this.structure_id)
      }
      const attrs = structure.getAssociation('attrs')
      if (typeof arg === 'number') {
        attr = attrs[arg]
      } else if (typeof arg === 'string') {
        attr = attrs.find((atr) => atr.key === arg)
      }
    }
    return attr
  }

  /**
   * get the entity's values of an attr
   * @param  {Number/String/QurttyModel} attr index/key of the attr, or attr itself
   * @return {Array}      of values
   */
  getValues (arg) {
    // If attr is attr.id
    // If attr is not number, then assume attr is an object
    const attr = this.parseAttr(arg)
    if (!attr) {
      return null
    }
    const attrId = attr.id
    return this.values.filter((val) => {
      return val.attr_id === attrId
    }).orderBy('display_order', 'asc').toModelArray()
  }

  getValue (arg) {
    return this.getValues(arg)[0]
  }

  getValueOrValues (arg) {
    const attr = this.parseAttr(arg)
    if (!attr) {
      return null
    }
    if (attr.has_multiple_values) {
      return this.getValues(attr)
    } else {
      return this.getValue(attr)
    }
  }

  parentValues () {
    return session.V3_Eav_Value.filter((val) => val.content_particle_type === 'V3::Eav::Entity' && val.content_particle_id === this.id).toModelArray()
  }

  coverText (locale = 'en', allowedAttrTypeNames = ATTR_TYPE_NAMES) {
    if (!this.structure) { return }

    const allAttrs = this.structure.getAss('attrs')
    const filteredAttrs = allAttrs.filter((attr) => allowedAttrTypeNames.includes(attr.typeName()))
    const value = this.getValue(filteredAttrs[0])
    return value ? value.coverText(locale) : ''
  }

  $createLinkedValue = ({ attr_id, particle }) => {
    return this.$createAssociation('values', {
      attr_id,
      content_particle_id: particle.id,
      content_particle_type: particle.model_class,
    })
  }

  $destroyLinkedValue = ({ attr_id, particle }) => {
    const value = this.getAss('values').find((val) => {
      return val.attr_id === attr_id && val.content_particle_type === particle.model_class && val.content_particle_id === particle.id
    })
    if (value) {
      return value.$destroy()
    } else {
      return Promise.resolve()
    }
  }
}

export class V3_Eav_Attr extends QurttyModel {
  static modelName = 'V3_Eav_Attr'
  static fields = {
    structure_id: fk({ to: 'V3_Eav_Structure', as: 'structure', relatedName: 'attrs' }),
  }

  type_type: any
  type_id: any

  type () {
    const modelName = session[this.type_type.replace(/::/g, '_')]
    if (!modelName) {
      console.log('Can not find modelName: ', this.type_type.replace(/::/g, '_'))
    }
    return modelName.withId(this.type_id)
  }

  typeName () {
    const thisType = this.type()
    if (thisType) {
      if (thisType.model_class === 'V3::Eav::ParticleType') {
        return thisType.class_name
      } else if (thisType.model_class === 'V3::Eav::AttrType') {
        return thisType.name
      } else {
        return 'V3::Eav::Entity'
      }
    } else {
      return null
    }
  }
}

export class V3_Eav_Value extends QurttyModel {
  static modelName = 'V3_Eav_Value'
  static fields = {
    entity_id: fk({ to: 'V3_Eav_Entity', as: 'entity', relatedName: 'values' }),
    attr_id: fk({ to: 'V3_Eav_Attr', as: 'attr', relatedName: 'values' }),
  }

  content_particle_type: any
  content_particle_id: any
  content_date: any
  content_number: any
  content_time: any
  attr: any
  file_attachment: any

  /**
   * get the photo/card/product/blog/event inside the value
   * @return {Photo/Card/Product/Blog/Event} [description]
   */
  content_particle () {
    if (this.content_particle_type) {
      const modelName = this.content_particle_type.replace(/::/g, '_')
      if (!session[modelName]) {
        console.log('wah!!', modelName)
      }
      return session[modelName].withId(this.content_particle_id)
    }
  }

  content (locale = 'en') {
    switch (this.attr.typeName()) {
      // attr.type_type ===  'V3::Eav::AttrType'
      case 'date':
        return this.content_date
        break
      case 'number':
        return this.content_number
        break
      case 'time':
        return this.content_time
        break
      case 'string':
        return this[`content_string_${locale}`]
        break
      case 'text':
        return this[`content_text_${locale}`]
        break
      case 'html':
        return this[`content_html_${locale}`]
        break
      case 'json':
        return get(this[`content_json_${locale}`], '[0].content')
        break

      // attr.type_type === 'V3::Eav::ParticleType'
      case 'V3::Photo':
      case 'V3::MemberCard':
      case 'V3::CompanyCard':
      case 'V3::Product':
      case 'V3::Blog':
      case 'V3::Event':
      case 'V3::Eav::Invitation':
        return this.content_particle()
        break
      case 'ActiveStorage::Blob':
        return this.file_attachment.blob
        break
      case 'V3::Eav::Entity':
        return this.content_particle()
        break
      default:
        return null
    }
  }

  coverText (locale = 'en') {
    switch (this.attr.typeName()) {
      // attr.type_type ===  'V3::Eav::AttrType'
      case 'date':
      case 'number':
      case 'time':
      case 'string':
      case 'text':
      case 'html':
      case 'json':
        return this.content(locale)

      // attr.type_type === 'V3::Eav::ParticleType'
      case 'V3::Product':
      case 'V3::Blog':
      case 'V3::Event':
      {
        const particle = this.content_particle()
        return particle ? particle[`name_${locale}`] : ''
      }
      case 'V3::MemberCard':
      {
        const particle = this.content_particle()
        return particle ? particle.printName(locale) : ''
      }
      case 'V3::Photo':
      {
        const particle = this.content_particle()
        return particle ? particle.name : ''
      }
      case 'V3::Eav::Invitation':
        return ''
      case 'V3::Eav::Entity':
      {
        const particle = this.content_particle()
        return particle ? particle.coverText(locale) : ''
      }
      default:
        return ''
    }
  }
}

export class V3_Eav_Invitation extends QurttyModel {
  static modelName = 'V3_Eav_Invitation'
  static fields = {}
}

export class V3_Eav_EntityPermission extends QurttyModel {
  static modelName = 'V3_Eav_EntityPermission'
  static fields = {
    entity_id: fk({ to: 'V3_Eav_Entity', as: 'entity', relatedName: 'entity_permissions' }),
    member_card_id: fk({ to: 'V3_MemberCard', as: 'member_card', relatedName: 'entity_permissions' }),
  }
}

export class V3_Eav_EntityComment extends QurttyModel {
  static modelName = 'V3_Eav_EntityComment'
  static fields = {
    entity_id: fk({ to: 'V3_Eav_Entity', as: 'entity', relatedName: 'entity_comments' }),
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'entity_comments' }),
  }
}

export class V3_Eav_EntityWebsiteStyle extends QurttyModel {
  static modelName = 'V3_Eav_EntityWebsiteStyle'
  static fields = {
    // entity_id: fk({ to: 'V3_Eav_Entity', as: 'entity', relatedName: 'entity_website_style' }),
    // grid_id: fk({ to: 'V3_Grid', as: 'grid', relatedName: 'entity_website_style' }),
  }
}

export class V3_StructureParticleParticipation extends QurttyModel {
  static modelName = 'V3_StructureParticleParticipation'
  static fields = {
    structure_id: fk({ to: 'V3_Eav_Structure', as: 'structure', relatedName: 'structure_particle_participations' }),
  }
}

export class V3_ProjectDistribution extends QurttyModel {
  static modelName = 'V3_ProjectDistribution'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'project_distributions' }),
  }
}

export class V3_Order extends QurttyModel {
  static modelName = 'V3_Order'
  static fields = {
    paid_by_user_id: fk({ to: 'Member', as: 'paid_by_user', relatedName: 'paid_for_orders' }),
  }

  static INCLUDES_PARAMS = {
    paypal_order_datum: {},
    mpgs_order_datum: {},
    fps_payment_datum: {},
    bank_transfer_payment_datum: {},
    receipt_attachment: { blob: {} },
  }
}

export class V3_Request extends QurttyModel {
  static modelName = 'V3_Request'
  static fields = {
    request_group_id: fk({ to: 'V3_RequestGroup', as: 'request_group', relatedName: 'requests' }),
    replaced_request_id: fk({ to: 'V3_Request', as: 'replaced_request', relatedName: 'replacement_requests' }),
  }

  static INCLUDES_PARAMS = {
    member_card: { phones: {}, addresses: {}, emails: {}, websites: {}, countries: {}, memberships: {} },
    company_card: { phones: {}, addresses: {}, emails: {}, websites: {}, countries: {} },
    v5_company_card_alias: {},
    order: V3_Order.INCLUDES_PARAMS,
    photos: {},
    member_card_inputs: { member_card: {} },
    particle_purchases: { product_snapshot: {} },
    event_purchases: {},
    subscription_intents: {},
  }

  getOrder () { return this.getPolymorphicOne('V3::Order', 'holder') }
  getMemberCard () { return this.getPolymorphicOne('V3::MemberCard', 'holder') }
  getCompanyCard () { return this.getPolymorphicOne('V3::CompanyCard', 'holder') }
}

export class V3_ParticlePurchase extends QurttyModel {
  static modelName = 'V3_ParticlePurchase'
  static fields = {
    request_id: fk({ to: 'V3_Request', as: 'request', relatedName: 'particle_purchases' }),
  }
}

export class V3_BankTransferPaymentDatum extends QurttyModel {
  static modelName = 'V3_BankTransferPaymentDatum'
  static fields = {
    order_id: oneToOne({ to: 'V3_Order', as: 'order', relatedName: 'bank_transfer_payment_datum' }),
    bank_transfer_beneficiary_config_id: fk({ to: 'V3_BankTransferBeneficiaryConfig', as: 'bank_transfer_beneficiary_config', relatedName: 'bank_transfer_payment_data' }),
  }
}

export class V3_FpsPaymentDatum extends QurttyModel {
  static modelName = 'V3_FpsPaymentDatum'
  static fields = {
    order_id: oneToOne({ to: 'V3_Order', as: 'order', relatedName: 'fps_payment_datum' }),
    fps_beneficiary_config_id: fk({ to: 'V3_FpsBeneficiaryConfig', as: 'fps_beneficiary_config', relatedName: 'fps_payment_data' }),
  }
}

export class V3_MemberCardInputSection extends QurttyModel {
  static modelName = 'V3_MemberCardInputSection'
  static fields = {
    holder_id: fk({ to: 'V3_RequestGroup', as: 'request_group', relatedName: 'member_card_input_sections' }),
  }
}

export class V3_Requestability extends QurttyModel {
  static modelName = 'V3_Requestability'
  static fields = {
    request_group_id: fk({ to: 'V3_RequestGroup', as: 'request_group', relatedName: 'requestabilities' }),
  }
}

export class V3_Purchasability extends QurttyModel {
  static modelName = 'V3_Purchasability'
  static fields = {
    request_group_id: fk({ to: 'V3_RequestGroup', as: 'request_group', relatedName: 'purchasabilities' }),
  }
}

export class V3_RequestGroup extends QurttyModel {
  static modelName = 'V3_RequestGroup'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'request_groups' }),
    invoice_logo_id: fk({ to: 'V3_Photo', as: 'invoice_logo', relatedName: 'invoice_logoed_request_groups' }),
    invoice_chop_id: fk({ to: 'V3_Photo', as: 'invoice_chop', relatedName: 'invoice_chopped_request_groups' }),
  }
}
export class V3_ProductCategory extends QurttyModel {
  static modelName = 'V3_ProductCategory'
  static fields = {
    host_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'product_categories' }),
    parent_category_id: fk({ to: 'V3_ProductCategory', as: 'parent_category', relatedName: 'child_categories' }),
  }
}
export class V3_BlogCategory extends QurttyModel {
  static modelName = 'V3_BlogCategory'
  static fields = {
    host_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'blog_categories' }),
    parent_category_id: fk({ to: 'V3_BlogCategory', as: 'parent_category', relatedName: 'child_categories' }),
  }
}
export class V3_MemberCardCategory extends QurttyModel {
  static modelName = 'V3_MemberCardCategory'
  static fields = {
    host_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'member_card_categories' }),
    parent_category_id: fk({ to: 'V3_MemberCardCategory', as: 'parent_category', relatedName: 'child_categories' }),
  }
}

export class V3_CompanyCardCategory extends QurttyModel {
  static modelName = 'V3_CompanyCardCategory'
  static fields = {
    host_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'company_card_categories' }),
    parent_category_id: fk({ to: 'V3_CompanyCardCategory', as: 'parent_category', relatedName: 'child_categories' }),
  }
}
export class V3_EventCategory extends QurttyModel {
  static modelName = 'V3_EventCategory'
  static fields = {
    host_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'event_categories' }),
    parent_category_id: fk({ to: 'V3_EventCategory', as: 'parent_category', relatedName: 'child_categories' }),
  }
}
export class V3_PhotoCategory extends QurttyModel {
  static modelName = 'V3_PhotoCategory'
  static fields = {
    host_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'photo_categories' }),
    parent_category_id: fk({ to: 'V3_PhotoCategory', as: 'parent_category', relatedName: 'child_categories' }),
  }
}
export class V3_SubscriptionPlan extends QurttyModel {
  static modelName = 'V3_SubscriptionPlan'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'subscription_plans' }),
  }
}

export class V3_SubscriptionDeal extends QurttyModel {
  static modelName = 'V3_SubscriptionDeal'
  static fields = {
    subscription_plan_id: fk({ to: 'V3_SubscriptionPlan', as: 'subscription_plan', relatedName: 'subscription_deals' }),
  }
}

export class V3_SubscriptionDemand extends QurttyModel {
  static modelName = 'V3_SubscriptionDemand'
  static fields = {
    subscription_plan_id: fk({ to: 'V3_SubscriptionPlan', as: 'subscription_plan', relatedName: 'subscription_demands' }),
  }
}

export class V3_SubscriptionApplication extends QurttyModel {
  static modelName = 'V3_SubscriptionApplication'
  static fields = {
    subscription_plan_id: fk({ to: 'V3_SubscriptionPlan', as: 'subscription_plan', relatedName: 'subscription_applications' }),
  }
}

export class V3_SectionParticleItemExtensionAttrExposure extends QurttyModel {
  static modelName = 'V3_SectionParticleItemExtensionAttrExposure'
  static fields = {
    attr_id: fk({ to: 'V3_Eav_Attr', as: 'attribute' }),
  }
}

export class V3_PaymentGatewayConfig extends QurttyModel {
  static modelName = 'V3_PaymentGatewayConfig'
  static fields = {
    website_control_id: oneToOne({ to: 'V3_WebsiteControl', as: 'website_control', relatedName: 'payment_gateway_config' }),
  }

  static INCLUDES_PARAMS = {
    paypal_gateway_config: {},
    mpgs_gateway_config: {},
    fps_beneficiary_configs: {},
    bank_transfer_beneficiary_configs: {},
  }
}

export class V3_BankTransferBeneficiaryConfig extends QurttyModel {
  static modelName = 'V3_BankTransferBeneficiaryConfig'
  static fields = {
    payment_gateway_config_id: fk({ to: 'V3_PaymentGatewayConfig', as: 'payment_gateway_config', relatedName: 'bank_transfer_beneficiary_configs' }),
  }
}

export class V3_FpsBeneficiaryConfig extends QurttyModel {
  static modelName = 'V3_FpsBeneficiaryConfig'
  static fields = {
    payment_gateway_config_id: fk({ to: 'V3_PaymentGatewayConfig', as: 'payment_gateway_config', relatedName: 'fps_beneficiary_configs' }),
  }
}

export class V3_MpgsGatewayConfig extends QurttyModel {
  static modelName = 'V3_MpgsGatewayConfig'
  static fields = {
    payment_gateway_config_id: oneToOne({ to: 'V3_PaymentGatewayConfig', as: 'payment_gateway_config', relatedName: 'mpgs_gateway_config' }),
  }
}

export class V3_PaypalGatewayConfig extends QurttyModel {
  static modelName = 'V3_PaypalGatewayConfig'
  static fields = {
    payment_gateway_config_id: oneToOne({ to: 'V3_PaymentGatewayConfig', as: 'payment_gateway_config', relatedName: 'paypal_gateway_config' }),
  }
}

export class V3_WebpageProductsSectionStyle extends QurttyModel {
  static modelName = 'V3_WebpageProductsSectionStyle'
  static fields = {
    webpage_section_id: oneToOne({ to: 'V3_WebpageSection', as: 'webpage_section', relatedName: 'webpage_products_section_style' }),
  }

  getSectionParticleItemExtensionAttrExposures () {
    // return filter(session.V3_ProductSegment, val => val.bloggable_type === 'V3::Product' && val.bloggable_id === this.id).toModelArray()
    return polymorphicAssoiciations(this, 'V3_SectionParticleItemExtensionAttrExposure', 'V3::WebpageProductsSectionStyle', 'holder')
  }
}

export class V3_WebpageContactUsSectionStyle extends QurttyModel {
  static modelName = 'V3_WebpageContactUsSectionStyle'
  static fields = {
    webpage_section_id: oneToOne({ to: 'V3_WebpageSection', as: 'webpage_section', relatedName: 'webpage_contact_us_section_style' }),
  }
}

export class V3_WebpageQuizGameSectionStyle extends QurttyModel {
  static modelName = 'V3_WebpageQuizGameSectionStyle'
  modelUrl = 'v3/webpage_quiz_game_section_styles'
  static fields = {
    webpage_section_id: oneToOne({ to: 'V3_WebpageSection', as: 'webpage_section', relatedName: 'webpage_quiz_game_section_style' }),
    game_logo_id: fk({ to: 'V3_Photo', as: 'game_logo', relatedName: 'game_logoed_webpage_quiz_game_section_styles' }),
    organization_logo_id: fk({ to: 'V3_Photo', as: 'organization_logo', relatedName: 'organization_logoed_webpage_quiz_game_section_styles' }),
    tv_home_background_photo_id: fk({ to: 'V3_Photo', as: 'tv_home_background_photo', relatedName: 'tv_home_backgrounded_webpage_quiz_game_section_styles' }),
    tv_login_background_photo_id: fk({ to: 'V3_Photo', as: 'tv_login_background_photo', relatedName: 'tv_login_backgrounded_webpage_quiz_game_section_styles' }),
    tv_paper_background_photo_id: fk({ to: 'V3_Photo', as: 'tv_paper_background_photo', relatedName: 'tv_paper_backgrounded_webpage_quiz_game_section_styles' }),
    tv_ranking_background_photo_id: fk({ to: 'V3_Photo', as: 'tv_ranking_background_photo', relatedName: 'tv_ranking_backgrounded_webpage_quiz_game_section_styles' }),
    tv_finished_background_photo_id: fk({ to: 'V3_Photo', as: 'tv_finished_background_photo', relatedName: 'tv_finished_backgrounded_webpage_quiz_game_section_styles' }),
    desktop_home_background_photo_id: fk({ to: 'V3_Photo', as: 'desktop_home_background_photo', relatedName: 'desktop_home_backgrounded_webpage_quiz_game_section_styles' }),
    desktop_login_background_photo_id: fk({ to: 'V3_Photo', as: 'desktop_login_background_photo', relatedName: 'desktop_login_backgrounded_webpage_quiz_game_section_styles' }),
    desktop_paper_background_photo_id: fk({ to: 'V3_Photo', as: 'desktop_paper_background_photo', relatedName: 'desktop_paper_backgrounded_webpage_quiz_game_section_styles' }),
    desktop_ranking_background_photo_id: fk({ to: 'V3_Photo', as: 'desktop_ranking_background_photo', relatedName: 'desktop_ranking_backgrounded_webpage_quiz_game_section_styles' }),
    desktop_finished_background_photo_id: fk({ to: 'V3_Photo', as: 'desktop_finished_background_photo', relatedName: 'desktop_finished_backgrounded_webpage_quiz_game_section_styles' }),
    mobile_home_background_photo_id: fk({ to: 'V3_Photo', as: 'mobile_home_background_photo', relatedName: 'mobile_home_backgrounded_webpage_quiz_game_section_styles' }),
    mobile_login_background_photo_id: fk({ to: 'V3_Photo', as: 'mobile_login_background_photo', relatedName: 'mobile_login_backgrounded_webpage_quiz_game_section_styles' }),
    mobile_paper_background_photo_id: fk({ to: 'V3_Photo', as: 'mobile_paper_background_photo', relatedName: 'mobile_paper_backgrounded_webpage_quiz_game_section_styles' }),
    mobile_ranking_background_photo_id: fk({ to: 'V3_Photo', as: 'mobile_ranking_background_photo', relatedName: 'mobile_ranking_backgrounded_webpage_quiz_game_section_styles' }),
    mobile_finished_background_photo_id: fk({ to: 'V3_Photo', as: 'mobile_finished_background_photo', relatedName: 'mobile_finished_backgrounded_webpage_quiz_game_section_styles' }),
    pad_home_background_photo_id: fk({ to: 'V3_Photo', as: 'pad_home_background_photo', relatedName: 'pad_home_backgrounded_webpage_quiz_game_section_styles' }),
    pad_login_background_photo_id: fk({ to: 'V3_Photo', as: 'pad_login_background_photo', relatedName: 'pad_login_backgrounded_webpage_quiz_game_section_styles' }),
    pad_paper_background_photo_id: fk({ to: 'V3_Photo', as: 'pad_paper_background_photo', relatedName: 'pad_paper_backgrounded_webpage_quiz_game_section_styles' }),
    pad_ranking_background_photo_id: fk({ to: 'V3_Photo', as: 'pad_ranking_background_photo', relatedName: 'pad_ranking_backgrounded_webpage_quiz_game_section_styles' }),
    pad_finished_background_photo_id: fk({ to: 'V3_Photo', as: 'pad_finished_background_photo', relatedName: 'pad_finished_backgrounded_webpage_quiz_game_section_styles' }),
    quizzer_avatar_id: fk({ to: 'V3_Photo', as: 'quizzer_avatar', relatedName: 'quizzer_avatared_webpage_quiz_game_section_styles' }),
    quizzer_avatar_selection_icon_id: fk({ to: 'V3_Photo', as: 'quizzer_avatar_selection_icon', relatedName: 'quizzer_avatar_selection_iconed_webpage_quiz_game_section_styles' }),
    answer_pending_icon_id: fk({ to: 'V3_Photo', as: 'answer_pending_icon', relatedName: 'answer_pending_iconed_webpage_quiz_game_section_styles' }),
    answer_selected_icon_id: fk({ to: 'V3_Photo', as: 'answer_selected_icon', relatedName: 'answer_selected_iconed_webpage_quiz_game_section_styles' }),
    answer_correct_icon_id: fk({ to: 'V3_Photo', as: 'answer_correct_icon', relatedName: 'answer_correct_iconed_webpage_quiz_game_section_styles' }),
    answer_incorrect_icon_id: fk({ to: 'V3_Photo', as: 'answer_incorrect_icon', relatedName: 'answer_incorrect_iconed_webpage_quiz_game_section_styles' }),
    quit_icon_id: fk({ to: 'V3_Photo', as: 'quit_icon', relatedName: 'quit_iconed_webpage_quiz_game_section_styles' }),
    expired_icon_id: fk({ to: 'V3_Photo', as: 'expired_icon', relatedName: 'expired_iconed_webpage_quiz_game_section_styles' }),
    timeout_icon_id: fk({ to: 'V3_Photo', as: 'timeout_icon', relatedName: 'timeout_iconed_webpage_quiz_game_section_styles' }),
    ranking_icon_id: fk({ to: 'V3_Photo', as: 'ranking_icon', relatedName: 'ranking_iconed_webpage_quiz_game_section_styles' }),
    ongoing_score_icon_id: fk({ to: 'V3_Photo', as: 'ongoing_score_icon', relatedName: 'ongoing_score_iconed_webpage_quiz_game_section_styles' }),
    final_score_icon_id: fk({ to: 'V3_Photo', as: 'final_score_icon', relatedName: 'final_score_iconed_webpage_quiz_game_section_styles' }),
    ranking_header_photo_id: fk({ to: 'V3_Photo', as: 'ranking_header_photo', relatedName: 'ranking_header_photoed_webpage_quiz_game_section_styles' }),
  }
}

export const projectModelRegister = (orm) => {
  orm.register(
    V3_Project,
    V3_ProjectCheckpoint,
    V3_ProjectDistribution,
    V3_Doc,
    V3_WebsiteControl,
    V3_WebsiteNavLink,
    V3_WebsiteDomain,
    V3_ExternalLink,
    V3_WebsiteNavGroup,
    V3_WaveStyle,
    V3_Webpage,
    V3_WebpageSection,
    V3_WebpageSectionAttr,
    V3_Eav_AttrType,
    V3_Eav_ParticleType,
    V3_Eav_Structure,
    V3_Eav_Entity,
    V3_Eav_Attr,
    V3_Eav_Value,
    V3_Eav_Invitation,
    V3_Eav_EntityPermission,
    V3_Eav_EntityComment,
    V3_Eav_EntityWebsiteStyle,
    V3_StructureParticleParticipation,
    V3_MemberCardInputSection,
    V3_RequestGroup,
    V3_Request,
    V3_Order,
    V3_ParticlePurchase,
    V3_BankTransferPaymentDatum,
    V3_FpsPaymentDatum,
    V3_Requestability,
    V3_Purchasability,
    V3_SubscriptionPlan,
    V3_SubscriptionDeal,
    V3_SubscriptionDemand,
    V3_SubscriptionApplication,
    V3_ProductCategory,
    V3_BlogCategory,
    V3_MemberCardCategory,
    V3_CompanyCardCategory,
    V3_EventCategory,
    V3_PhotoCategory,
    V3_SectionParticleItemExtensionAttrExposure,
    V3_PaymentGatewayConfig,
    V3_BankTransferBeneficiaryConfig,
    V3_FpsBeneficiaryConfig,
    V3_MpgsGatewayConfig,
    V3_PaypalGatewayConfig,
    V3_SeoSetting,
    V3_SeoKeyword,
    V3_WebpageProductsSectionStyle,
    V3_WebpageContactUsSectionStyle,
    V3_WebpageQuizGameSectionStyle,
  )
}
